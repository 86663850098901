import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaPinterest.module.css';

const IconSocialMediaPinterest = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox='0 0 128 128' id="pinterest"><path fill="#231f20" d="M63.998 7.989c-30.883 0-56.008 25.126-56.008 56.01 0 30.885 25.125 56.012 56.008 56.012 30.885 0 56.012-25.127 56.012-56.012 0-30.884-25.127-56.01-56.012-56.01zm.184 31.749c-17.789 0-26.756 12.755-26.756 23.388 0 6.439 2.434 12.168 7.666 14.301.857.351 1.623.014 1.873-.939.172-.656.588-2.311.76-3.004.256-.938.158-1.266-.535-2.084-1.504-1.78-2.467-4.08-2.467-7.345 0-9.458 7.074-17.924 18.428-17.924 10.053 0 15.576 6.142 15.576 14.343 0 10.797-4.775 19.902-11.871 19.902-3.912 0-6.842-3.238-5.902-7.209 1.121-4.742 3.305-9.863 3.305-13.285 0-3.062-1.646-5.617-5.047-5.617-4.006 0-7.227 4.145-7.227 9.689 0 3.532 1.197 5.919 1.197 5.919s-4.096 17.361-4.816 20.405c-.668 2.84-.756 5.977-.65 8.595-13.191-5.79-22.408-18.96-22.408-34.288 0-20.679 16.762-37.438 37.436-37.438s37.434 16.76 37.434 37.438-16.76 37.436-37.434 37.436a37.49 37.49 0 0 1-10.609-1.533c1.422-2.248 2.977-5.137 3.77-8.02.457-1.666 2.635-10.291 2.635-10.291 1.299 2.479 5.1 4.664 9.143 4.664 12.035 0 20.203-10.97 20.203-25.654-.003-11.106-9.413-21.449-23.704-21.449z"></path></svg>
  );
};

IconSocialMediaPinterest.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaPinterest.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaPinterest;
