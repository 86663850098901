
import { dynamicContent } from "../../util/api";
import { storableError } from "../../util/errors";

// ================ Action types ================ //

export const DYNAMIC_PAGE_REQUEST = 'app/TermsOfServicePage/DYNAMIC_PAGE_REQUEST';
export const DYNAMIC_PAGE_SUCCESS = 'app/TermsOfServicePage/DYNAMIC_PAGE_SUCCESS';
export const DYNAMIC_PAGE_ERROR = 'app/TermsOfServicePage/DYNAMIC_PAGE_ERROR';

// ================ Reducer ================ //
const initialState = {
  fetchBlogInProgress: false,
  dynamicData: null,
  fetchBlogError: null,
};

export const TermsOfServicePageLoader = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DYNAMIC_PAGE_REQUEST:
      return {
        ...state,
        fetchBlogInProgress: true,
        fetchBlogError: null,
      }
    case DYNAMIC_PAGE_SUCCESS:
      return {
        ...state,
        fetchBlogInProgress: false,
        dynamicData: payload,
        fetchBlogError: null,
      }
    case DYNAMIC_PAGE_ERROR:
      return {
        ...state,
        fetchBlogInProgress: false,
        fetchBlogError: payload,
      }
    default:
      return state;
  }
}

export default TermsOfServicePageLoader;

// ================ Action creators ================ //

export const DynamicContentPageRequest = searchParams => ({
  type: DYNAMIC_PAGE_REQUEST,
  payload: { searchParams },
});

export const DynamicContentPageSuccess = response => ({
  type: DYNAMIC_PAGE_SUCCESS,
  payload: { data: response.data },
});

export const DynamicContentPageError = e => ({
  type: DYNAMIC_PAGE_ERROR,
  error: true,
  payload: e,
});


export const loadData = (params) => dispatch => {
 const { id,  } = params;
 const tab = 'terms'
  dispatch(DynamicContentPageRequest());

  return dynamicContent({ id, tab })
    .then(res => dispatch(DynamicContentPageSuccess(res)))
    .catch(err => dispatch(DynamicContentPageError(storableError(err))));
};
