import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './TermsOfService.module.css';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';

const TermsOfService = props => {
  const { rootClassName, className,dynamicData } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
     {
       
         <div className={css.content}>
           {/* <h1>{dynamicData.data.attributes.heading}</h1> */}
           <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
             {dynamicData?.data?.attributes?.content}
           </ReactMarkdown>
         </div>
     }
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
