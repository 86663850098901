import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../../config';
import {
  FormattedMessage,
  intlShape,
  injectIntl,
} from '../../../util/reactIntl';
import {
  required,
  bookingDatesRequired,
  composeValidators,
} from '../../../util/validators';
import { START_DATE, END_DATE, getStartOf, addTime } from '../../../util/dates';
import { propTypes } from '../../../util/types';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldDateRangeInput,
  FieldSelect,
  FieldTextInput,
  NamedLink,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';
import css from './BookingDatesForm.module.css';

const identity = v => v;

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the orderData object.
  handleOnChange(formValues, form) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates
        ? formValues.values.bookingDates
        : {};
    const { saleOrRent } = this.props;
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    const { deliveryMethod, ratePerDays, selectSize } = formValues.values;
    const endDateComputed =
      startDate && addTime(startDate, ratePerDays, 'days');
    if (
      (!endDate && startDate) ||
      (endDate &&
        moment(endDate).format('YYYY-MMM-DD') !=
        moment(endDateComputed).format('YYYY-MMM-DD'))
    ) {
      form.change('bookingDates', { startDate, endDate: endDateComputed });
    }
    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        orderData: {
          bookingDates: { startDate, endDate: endDate },
          bookingStart: startDate,
          bookingEnd: endDateComputed,
          deliveryMethod,
          selectSize,
          saleOrRent,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      pickupEnabled,
      shippingEnabled,
      currentStock,
      listing,
      currentUser,
      ...rest
    } = this.props;
    const { publicData, description, price } = listing?.attributes || {};
    const { sizes, size, stock_size, ReailPrice } = publicData || {};
    const classes = classNames(rootClassName || css.root, className);
    const { pickupLocation, pickupLocation2, postal } = publicData || {};
    const country = listing?.attributes?.publicData?.location?.address || {};

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }
    const singleDeliveryMethodAvailableMaybe =
      shippingEnabled && !pickupEnabled
        ? { deliveryMethod: 'shipping' }
        : !shippingEnabled && pickupEnabled
          ? { deliveryMethod: 'pickup' }
          : {};
    const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
    const initialValues = {
      ratePerDays: 3,
      ...singleDeliveryMethodAvailableMaybe,
    };

    return (
      <FinalForm
        {...rest}
        initialValues={initialValues}
        hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            form,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            hasMultipleDeliveryMethods,
          } = fieldRenderProps;
          const hasStock = currentStock && currentStock > 0;

          const { startDate, endDate } =
            values && values.bookingDates ? values.bookingDates : {};

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const breakdownData =
            startDate && endDate
              ? {
                startDate,
                endDate,
              }
              : null;

          const showEstimatedBreakdown =
            breakdownData &&
            lineItems &&
            !fetchLineItemsInProgress &&
            !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedCustomerBreakdownMaybe
                unitType={unitType}
                breakdownData={breakdownData}
                lineItems={lineItems}
                selectSize={values.selectSize}
                publicData={publicData}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = new Date();
          const today = getStartOf(now);
          const defaultStartDate = addTime(today, 1, 'days');
          const defaultEndDate = addTime(defaultStartDate, 3, 'days');
          const startDatePlaceholderText =
            startDatePlaceholder ||
            intl.formatDate(defaultStartDate, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder ||
            intl.formatDate(defaultEndDate, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );
          const priceOptions = [];
          priceOptions.push({
            key: 3,
            value: Math.round(price.amount / 100),
            title: '3 Days',
          });
          priceOptions.push({
            key: 5,
            value: priceOptions[0].value + (priceOptions[0].value * 35) / 100,
            title: '5 Days',
          });
          priceOptions.push({
            key: 7,
            value: priceOptions[1].value + (priceOptions[1].value * 35) / 100,
            title: '7 Days',
          });
          priceOptions.push({
            key: 10,
            value: priceOptions[2].value + (priceOptions[2].value * 35) / 100,
            title: '10 Days',
          });

          return (
            <Form
              onSubmit={handleSubmit}
              className={classes}
              enforcePagePreloadFor="CheckoutPage"
            >
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values, form);
                }}
              />
              <div className={css.rentalDuration}>
                <div className={css.productSize}>
                  <FormattedMessage id="BookingDatesForm.rental" />
                  <FieldSelect id="ratePerDays" name="ratePerDays">
                    {priceOptions &&
                      priceOptions.length &&
                      priceOptions.map((itm, i) => {
                        return (
                          <option key={itm.key} value={itm.key} >
                            {itm.title}/ €{i == 0 ? Math.round(itm.value) : Math.round(itm.value / 5) * 5}
                          </option>
                        );
                      })}
                  </FieldSelect>
                </div>
                {stock_size == 'yes' ? (
                  <div className={css.productSize}>
                    <FormattedMessage id="BookingDatesForm.sizes" />
                    <FieldSelect id="selectSize" name="selectSize" required>
                      <option value="">Select sizes</option>
                      {sizes &&
                        sizes.map(e => {
                          return (
                            parseInt(e.stock) > 0 ? (
                              <>
                                <option key={e.size_acc_to_stock} value={e.size_acc_to_stock}>
                                  {e.size_acc_to_stock}
                                </option>
                              </>
                            ) : null
                          );
                        })}
                    </FieldSelect>
                  </div>
                ) : (
                  <div className={css.productSize}>
                    <div className={css.productSizeBox}>
                      <FormattedMessage id="BookingDatesForm.sizes" />
                    </div>
                    <FieldSelect id="selectSize" name="selectSize" required>
                      <option value="">Select sizes</option>
                      {size &&
                        size.map(e => {
                          return (
                            <option key={e.label} value={e.key}>
                              {e.label}
                            </option>
                          );
                        })}
                    </FieldSelect>
                  </div>
                )}
              </div>
              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                minimumNights={values.ratePerDays}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots?.slice(1, timeSlots?.length)}

                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(
                    startDateErrorMessage,
                    endDateErrorMessage
                  )
                )}
                disabled={fetchLineItemsInProgress}
              />
              {hasMultipleDeliveryMethods ? (
                <div className={css.deliveryMethod}>
                  <FieldSelect
                    id={`${formId}.deliveryMethod`}
                    className={css.deliveryField}
                    name="deliveryMethod"
                    disabled={!hasStock}
                    label={intl.formatMessage({
                      id: 'ProductOrderForm.deliveryMethodLabel',
                    })}
                    validate={required(
                      intl.formatMessage({
                        id: 'ProductOrderForm.deliveryMethodRequired',
                      })
                    )}
                  >
                    <option disabled value="">
                      {intl.formatMessage({
                        id: 'ProductOrderForm.selectDeliveryMethodOption',
                      })}
                    </option>
                    <option value={'pickup'}>
                      {intl.formatMessage({
                        id: 'ProductOrderForm.pickupOption',
                      })}
                    </option>
                    <option value={'shipping'}>
                      {intl.formatMessage({
                        id: 'ProductOrderForm.shippingOption',
                      })}
                    </option>
                  </FieldSelect>
                </div>
              ) : (
                <div className={css.deliveryField}>
                  <label>
                    {intl.formatMessage({
                      id: 'ProductOrderForm.deliveryMethodLabel',
                    })}
                  </label>
                  <p className={css.singleDeliveryMethodSelected}>
                    {values.deliveryMethod === 'shipping'
                      ? intl.formatMessage({
                        id: 'ProductOrderForm.shippingOption',
                      })
                      : intl.formatMessage({
                        id: 'ProductOrderForm.pickupOption',
                      })}
                  </p>
                  <FieldTextInput
                    id={`${formId}.deliveryMethod`}
                    className={css.deliveryField}
                    name="deliveryMethod"
                    type="hidden"
                  />
                </div>
              )}

              {values.deliveryMethod &&
                values.deliveryMethod.includes('pickup') && (
                  
                  <div>
                    <h5>Pickup Address</h5>
                    <span>
                    {pickupLocation}
                    <br />
                    {pickupLocation2}
                    <br />
                    {postal}
                    <br />
                    {country}
                  </span>
                  </div>

                )}
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              {currentUser && currentUser.attributes.emailVerified == false ? (
                <div>
                  <p>Please verify your email first.</p>
                  <NamedLink name="ContactDetailsPage">
                    <p>Click here!</p>
                  </NamedLink>
                </div>
              ) : null}
              <div className={submitButtonClasses}>
                <PrimaryButton
                  type="submit"
                  disabled={
                    isOwnListing ||
                    (currentUser &&
                      currentUser.attributes.emailVerified == false)
                  }
                >
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.lineItemUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
