/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const category = [
  { key: 'Dresses', value: 'dresses', label: 'Dresses' },
  { key: 'Trousers/Jeans', value: 'trouser_jeans', label: 'Trousers/Jeans' },
  { key: 'Shoes', value: 'shoes', label: 'Shoes' },
  { key: 'Skirts', value: 'skirts', label: 'Skirts' },
  { key: 'Tops', value: 'tops', label: 'Tops' },
  { key: 'Suits', value: 'suits', label: 'Suits' },
  { key: 'Coats', value: 'coats', label: 'Coats' },
  { key: 'Jumpsuits', value: 'jumpsuits', label: 'Jumpsuits' },
  { key: 'Playsuits', value: 'playsuits', label: 'Playsuits' },
  { key: 'Bag', value: 'bag', label: 'Bag' },
  { key: 'Accessories', value: 'accessories', label: 'Accessories' },
];

export const listingtypes = [
  { key: 'for_rent', value: 'For Rent', label: 'For Rent' },
  { key: 'for_sale', value: 'For Sale', label: 'For Sale' },
  { key: 'For Sale/Rent', value: 'For Sale/Rent', label: 'For Rent/For Sale' },
  // { key: "services", value: "services", label: "Services" },
];

export const units = [
  { key: '1', value: '1', label: '1' },
  { key: '2', value: '2', label: '2' },
  { key: '3', value: '3', label: '3' },
  { key: '4', value: '4', label: '4' },
  { key: '5', value: '5', label: '5' },
];

export const prefrence = [
  { key: 'post_mandatory', value: 'post_mandatory', label: 'Post Mandatory' },
  {
    key: 'meet_in_person',
    value: 'meet_in_person',
    label: 'Meet in person (Optional)',
  },
];

export const dressSize = [
  { value: 'accessories', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'accessories', key: 'XXS', label: 'XXS' },
  { value: 'accessories', key: 'XS', label: 'XS' },
  { value: 'accessories', key: 'XS/S', label: 'XS/S' },
  { value: 'accessories', key: 'S', label: 'S' },
  { value: 'accessories', key: 'S/M', label: 'S/M' },
  { value: 'accessories', key: 'M', label: 'M' },
  { value: 'accessories', key: 'M/L', label: 'M/L' },
  { value: 'accessories', key: 'L', label: 'L' },
  { value: 'accessories', key: 'L/XL', label: 'L/XL' },
  { value: 'accessories', key: 'XL', label: 'XL' },
  { value: 'accessories', key: 'XXL', label: 'XXL' },
  { value: 'accessories', key: 'XXXL', label: 'XXXL' },
  { value: 'accessories', key: 'EU 30', label: 'EU 30' },
  { value: 'accessories', key: 'EU 32', label: 'EU 32' },
  { value: 'accessories', key: 'EU 34', label: 'EU 34' },
  { value: 'accessories', key: 'EU 36', label: 'EU 36' },
  { value: 'accessories', key: 'EU 38', label: 'EU 38' },
  { value: 'accessories', key: 'EU 40', label: 'EU 40' },
  { value: 'accessories', key: 'EU 42', label: 'EU 42' },
  { value: 'accessories', key: 'EU 44', label: 'EU 44' },
  { value: 'accessories', key: 'EU 46', label: 'EU 46' },
  { value: 'accessories', key: 'EU 48', label: 'EU 48' },
  { value: 'accessories', key: 'EU 50', label: 'EU 50' },
  { value: 'accessories', key: 'EU 52', label: 'EU 52' },
  { value: 'accessories', key: 'EU 54', label: 'EU 54' },
  { value: 'accessories', key: 'EU 56', label: 'EU 56' },
  { value: 'accessories', key: 'EU 58', label: 'EU 58' },
  { value: 'accessories', key: 'W23', label: 'W23' },
  { value: 'accessories', key: 'W25', label: 'W25' },
  { value: 'accessories', key: 'W27', label: 'W27' },
  { value: 'accessories', key: 'W29', label: 'W29' },
  { value: 'accessories', key: 'W31', label: 'W31' },
  { value: 'accessories', key: 'W33', label: 'W33' },
  { value: 'accessories', key: 'US 0', label: 'US 0' },
  { value: 'accessories', key: 'US 2', label: 'US 2' },
  { value: 'accessories', key: 'US 4', label: 'US 4' },
  { value: 'accessories', key: 'US 6', label: 'US 6' },
  { value: 'accessories', key: 'US 8', label: 'US 8' },
  { value: 'accessories', key: 'US 10', label: 'US 10' },
  { value: 'accessories', key: 'US 12', label: 'US 12' },
  { value: 'accessories', key: 'US 14', label: 'US 14' },
  { value: 'accessories', key: 'US 16', label: 'US 16' },
  { value: 'accessories', key: 'US 18', label: 'US 18' },
  { value: 'accessories', key: 'US 20', label: 'US 20' },
  { value: 'accessories', key: 'US 22', label: 'US 22' },
  { value: 'accessories', key: 'US 24', label: 'US 24' },
  { value: 'accessories', key: 'US 26', label: 'US 26' },
  { value: 'accessories', key: 'US 28', label: 'US 28' },
  { value: 'accessories', key: 'US 30', label: 'US 30' },
  { value: 'accessories', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'accessories', key: 'XXS', label: 'XXS' },
  { value: 'accessories', key: 'XS', label: 'XS' },
  { value: 'accessories', key: 'XS/S', label: 'XS/S' },
  { value: 'accessories', key: 'S', label: 'S' },
  { value: 'accessories', key: 'S/M', label: 'S/M' },
  { value: 'accessories', key: 'M', label: 'M' },
  { value: 'accessories', key: 'M/L', label: 'M/L' },
  { value: 'accessories', key: 'L', label: 'L' },
  { value: 'accessories', key: 'L/XL', label: 'L/XL' },
  { value: 'accessories', key: 'XL', label: 'XL' },
  { value: 'accessories', key: 'XXL', label: 'XXL' },
  { value: 'accessories', key: 'XXXL', label: 'XXXL' },
  { value: 'accessories', key: 'EU 30', label: 'EU 30' },
  { value: 'accessories', key: 'EU 32', label: 'EU 32' },
  { value: 'accessories', key: 'EU 34', label: 'EU 34' },
  { value: 'accessories', key: 'EU 36', label: 'EU 36' },
  { value: 'accessories', key: 'EU 38', label: 'EU 38' },
  { value: 'accessories', key: 'EU 40', label: 'EU 40' },
  { value: 'accessories', key: 'EU 42', label: 'EU 42' },
  { value: 'accessories', key: 'EU 44', label: 'EU 44' },
  { value: 'accessories', key: 'EU 46', label: 'EU 46' },
  { value: 'accessories', key: 'EU 48', label: 'EU 48' },
  { value: 'accessories', key: 'EU 50', label: 'EU 50' },
  { value: 'accessories', key: 'EU 52', label: 'EU 52' },
  { value: 'accessories', key: 'EU 54', label: 'EU 54' },
  { value: 'accessories', key: 'EU 56', label: 'EU 56' },
  { value: 'accessories', key: 'EU 58', label: 'EU 58' },
  { value: 'accessories', key: 'W23', label: 'W23' },
  { value: 'accessories', key: 'W25', label: 'W25' },
  { value: 'accessories', key: 'W27', label: 'W27' },
  { value: 'accessories', key: 'W29', label: 'W29' },
  { value: 'accessories', key: 'W31', label: 'W31' },
  { value: 'accessories', key: 'W33', label: 'W33' },
  { value: 'accessories', key: 'US 0', label: 'US 0' },
  { value: 'accessories', key: 'US 2', label: 'US 2' },
  { value: 'accessories', key: 'US 4', label: 'US 4' },
  { value: 'accessories', key: 'US 6', label: 'US 6' },
  { value: 'accessories', key: 'US 8', label: 'US 8' },
  { value: 'accessories', key: 'US 10', label: 'US 10' },
  { value: 'accessories', key: 'US 12', label: 'US 12' },
  { value: 'accessories', key: 'US 14', label: 'US 14' },
  { value: 'accessories', key: 'US 16', label: 'US 16' },
  { value: 'accessories', key: 'US 18', label: 'US 18' },
  { value: 'accessories', key: 'US 20', label: 'US 20' },
  { value: 'accessories', key: 'US 22', label: 'US 22' },
  { value: 'accessories', key: 'US 24', label: 'US 24' },
  { value: 'accessories', key: 'US 26', label: 'US 26' },
  { value: 'accessories', key: 'US 28', label: 'US 28' },
  { value: 'accessories', key: 'US 30', label: 'US 30' },
  { value: 'bag', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'bag', key: 'XXS', label: 'XXS' },
  { value: 'bag', key: 'XS', label: 'XS' },
  { value: 'bag', key: 'XS/S', label: 'XS/S' },
  { value: 'bag', key: 'S', label: 'S' },
  { value: 'bag', key: 'S/M', label: 'S/M' },
  { value: 'bag', key: 'M', label: 'M' },
  { value: 'bag', key: 'M/L', label: 'M/L' },
  { value: 'bag', key: 'L', label: 'L' },
  { value: 'bag', key: 'L/XL', label: 'L/XL' },
  { value: 'bag', key: 'XL', label: 'XL' },
  { value: 'bag', key: 'XXL', label: 'XXL' },
  { value: 'bag', key: 'XXXL', label: 'XXXL' },
  { value: 'bag', key: 'EU 30', label: 'EU 30' },
  { value: 'bag', key: 'EU 32', label: 'EU 32' },
  { value: 'bag', key: 'EU 34', label: 'EU 34' },
  { value: 'bag', key: 'EU 36', label: 'EU 36' },
  { value: 'bag', key: 'EU 38', label: 'EU 38' },
  { value: 'bag', key: 'EU 40', label: 'EU 40' },
  { value: 'bag', key: 'EU 42', label: 'EU 42' },
  { value: 'bag', key: 'EU 44', label: 'EU 44' },
  { value: 'bag', key: 'EU 46', label: 'EU 46' },
  { value: 'bag', key: 'EU 48', label: 'EU 48' },
  { value: 'bag', key: 'EU 50', label: 'EU 50' },
  { value: 'bag', key: 'EU 52', label: 'EU 52' },
  { value: 'bag', key: 'EU 54', label: 'EU 54' },
  { value: 'bag', key: 'EU 56', label: 'EU 56' },
  { value: 'bag', key: 'EU 58', label: 'EU 58' },
  { value: 'bag', key: 'W23', label: 'W23' },
  { value: 'bag', key: 'W25', label: 'W25' },
  { value: 'bag', key: 'W27', label: 'W27' },
  { value: 'bag', key: 'W29', label: 'W29' },
  { value: 'bag', key: 'W31', label: 'W31' },
  { value: 'bag', key: 'W33', label: 'W33' },
  { value: 'bag', key: 'US 0', label: 'US 0' },
  { value: 'bag', key: 'US 2', label: 'US 2' },
  { value: 'bag', key: 'US 4', label: 'US 4' },
  { value: 'bag', key: 'US 6', label: 'US 6' },
  { value: 'bag', key: 'US 8', label: 'US 8' },
  { value: 'bag', key: 'US 10', label: 'US 10' },
  { value: 'bag', key: 'US 12', label: 'US 12' },
  { value: 'bag', key: 'US 14', label: 'US 14' },
  { value: 'bag', key: 'US 16', label: 'US 16' },
  { value: 'bag', key: 'US 18', label: 'US 18' },
  { value: 'bag', key: 'US 20', label: 'US 20' },
  { value: 'bag', key: 'US 22', label: 'US 22' },
  { value: 'bag', key: 'US 24', label: 'US 24' },
  { value: 'bag', key: 'US 26', label: 'US 26' },
  { value: 'bag', key: 'US 28', label: 'US 28' },
  { value: 'bag', key: 'US 30', label: 'US 30' },
  { value: 'bag', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'bag', key: 'XXS', label: 'XXS' },
  { value: 'bag', key: 'XS', label: 'XS' },
  { value: 'bag', key: 'XS/S', label: 'XS/S' },
  { value: 'bag', key: 'S', label: 'S' },
  { value: 'bag', key: 'S/M', label: 'S/M' },
  { value: 'bag', key: 'M', label: 'M' },
  { value: 'bag', key: 'M/L', label: 'M/L' },
  { value: 'bag', key: 'L', label: 'L' },
  { value: 'bag', key: 'L/XL', label: 'L/XL' },
  { value: 'bag', key: 'XL', label: 'XL' },
  { value: 'bag', key: 'XXL', label: 'XXL' },
  { value: 'bag', key: 'XXXL', label: 'XXXL' },
  { value: 'bag', key: 'EU 30', label: 'EU 30' },
  { value: 'bag', key: 'EU 32', label: 'EU 32' },
  { value: 'bag', key: 'EU 34', label: 'EU 34' },
  { value: 'bag', key: 'EU 36', label: 'EU 36' },
  { value: 'bag', key: 'EU 38', label: 'EU 38' },
  { value: 'bag', key: 'EU 40', label: 'EU 40' },
  { value: 'bag', key: 'EU 42', label: 'EU 42' },
  { value: 'bag', key: 'EU 44', label: 'EU 44' },
  { value: 'bag', key: 'EU 46', label: 'EU 46' },
  { value: 'bag', key: 'EU 48', label: 'EU 48' },
  { value: 'bag', key: 'EU 50', label: 'EU 50' },
  { value: 'bag', key: 'EU 52', label: 'EU 52' },
  { value: 'bag', key: 'EU 54', label: 'EU 54' },
  { value: 'bag', key: 'EU 56', label: 'EU 56' },
  { value: 'bag', key: 'EU 58', label: 'EU 58' },
  { value: 'bag', key: 'W23', label: 'W23' },
  { value: 'bag', key: 'W25', label: 'W25' },
  { value: 'bag', key: 'W27', label: 'W27' },
  { value: 'bag', key: 'W29', label: 'W29' },
  { value: 'bag', key: 'W31', label: 'W31' },
  { value: 'bag', key: 'W33', label: 'W33' },
  { value: 'bag', key: 'US 0', label: 'US 0' },
  { value: 'bag', key: 'US 2', label: 'US 2' },
  { value: 'bag', key: 'US 4', label: 'US 4' },
  { value: 'bag', key: 'US 6', label: 'US 6' },
  { value: 'bag', key: 'US 8', label: 'US 8' },
  { value: 'bag', key: 'US 10', label: 'US 10' },
  { value: 'bag', key: 'US 12', label: 'US 12' },
  { value: 'bag', key: 'US 14', label: 'US 14' },
  { value: 'bag', key: 'US 16', label: 'US 16' },
  { value: 'bag', key: 'US 18', label: 'US 18' },
  { value: 'bag', key: 'US 20', label: 'US 20' },
  { value: 'bag', key: 'US 22', label: 'US 22' },
  { value: 'bag', key: 'US 24', label: 'US 24' },
  { value: 'bag', key: 'US 26', label: 'US 26' },
  { value: 'bag', key: 'US 28', label: 'US 28' },
  { value: 'bag', key: 'US 30', label: 'US 30' },
  { value: 'playsuits', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'playsuits', key: 'XXS', label: 'XXS' },
  { value: 'playsuits', key: 'XS', label: 'XS' },
  { value: 'playsuits', key: 'XS/S', label: 'XS/S' },
  { value: 'playsuits', key: 'S', label: 'S' },
  { value: 'playsuits', key: 'S/M', label: 'S/M' },
  { value: 'playsuits', key: 'M', label: 'M' },
  { value: 'playsuits', key: 'M/L', label: 'M/L' },
  { value: 'playsuits', key: 'L', label: 'L' },
  { value: 'playsuits', key: 'L/XL', label: 'L/XL' },
  { value: 'playsuits', key: 'XL', label: 'XL' },
  { value: 'playsuits', key: 'XXL', label: 'XXL' },
  { value: 'playsuits', key: 'XXXL', label: 'XXXL' },
  { value: 'playsuits', key: 'EU 30', label: 'EU 30' },
  { value: 'playsuits', key: 'EU 32', label: 'EU 32' },
  { value: 'playsuits', key: 'EU 34', label: 'EU 34' },
  { value: 'playsuits', key: 'EU 36', label: 'EU 36' },
  { value: 'playsuits', key: 'EU 38', label: 'EU 38' },
  { value: 'playsuits', key: 'EU 40', label: 'EU 40' },
  { value: 'playsuits', key: 'EU 42', label: 'EU 42' },
  { value: 'playsuits', key: 'EU 44', label: 'EU 44' },
  { value: 'playsuits', key: 'EU 46', label: 'EU 46' },
  { value: 'playsuits', key: 'EU 48', label: 'EU 48' },
  { value: 'playsuits', key: 'EU 50', label: 'EU 50' },
  { value: 'playsuits', key: 'EU 52', label: 'EU 52' },
  { value: 'playsuits', key: 'EU 54', label: 'EU 54' },
  { value: 'playsuits', key: 'EU 56', label: 'EU 56' },
  { value: 'playsuits', key: 'EU 58', label: 'EU 58' },
  { value: 'playsuits', key: 'W23', label: 'W23' },
  { value: 'playsuits', key: 'W25', label: 'W25' },
  { value: 'playsuits', key: 'W27', label: 'W27' },
  { value: 'playsuits', key: 'W29', label: 'W29' },
  { value: 'playsuits', key: 'W31', label: 'W31' },
  { value: 'playsuits', key: 'W33', label: 'W33' },
  { value: 'playsuits', key: 'US 0', label: 'US 0' },
  { value: 'playsuits', key: 'US 2', label: 'US 2' },
  { value: 'playsuits', key: 'US 4', label: 'US 4' },
  { value: 'playsuits', key: 'US 6', label: 'US 6' },
  { value: 'playsuits', key: 'US 8', label: 'US 8' },
  { value: 'playsuits', key: 'US 10', label: 'US 10' },
  { value: 'playsuits', key: 'US 12', label: 'US 12' },
  { value: 'playsuits', key: 'US 14', label: 'US 14' },
  { value: 'playsuits', key: 'US 16', label: 'US 16' },
  { value: 'playsuits', key: 'US 18', label: 'US 18' },
  { value: 'playsuits', key: 'US 20', label: 'US 20' },
  { value: 'playsuits', key: 'US 22', label: 'US 22' },
  { value: 'playsuits', key: 'US 24', label: 'US 24' },
  { value: 'playsuits', key: 'US 26', label: 'US 26' },
  { value: 'playsuits', key: 'US 28', label: 'US 28' },
  { value: 'playsuits', key: 'US 30', label: 'US 30' },
  { value: 'playsuits', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'playsuits', key: 'XXS', label: 'XXS' },
  { value: 'playsuits', key: 'XS', label: 'XS' },
  { value: 'playsuits', key: 'XS/S', label: 'XS/S' },
  { value: 'playsuits', key: 'S', label: 'S' },
  { value: 'playsuits', key: 'S/M', label: 'S/M' },
  { value: 'playsuits', key: 'M', label: 'M' },
  { value: 'playsuits', key: 'M/L', label: 'M/L' },
  { value: 'playsuits', key: 'L', label: 'L' },
  { value: 'playsuits', key: 'L/XL', label: 'L/XL' },
  { value: 'playsuits', key: 'XL', label: 'XL' },
  { value: 'playsuits', key: 'XXL', label: 'XXL' },
  { value: 'playsuits', key: 'XXXL', label: 'XXXL' },
  { value: 'playsuits', key: 'EU 30', label: 'EU 30' },
  { value: 'playsuits', key: 'EU 32', label: 'EU 32' },
  { value: 'playsuits', key: 'EU 34', label: 'EU 34' },
  { value: 'playsuits', key: 'EU 36', label: 'EU 36' },
  { value: 'playsuits', key: 'EU 38', label: 'EU 38' },
  { value: 'playsuits', key: 'EU 40', label: 'EU 40' },
  { value: 'playsuits', key: 'EU 42', label: 'EU 42' },
  { value: 'playsuits', key: 'EU 44', label: 'EU 44' },
  { value: 'playsuits', key: 'EU 46', label: 'EU 46' },
  { value: 'playsuits', key: 'EU 48', label: 'EU 48' },
  { value: 'playsuits', key: 'EU 50', label: 'EU 50' },
  { value: 'playsuits', key: 'EU 52', label: 'EU 52' },
  { value: 'playsuits', key: 'EU 54', label: 'EU 54' },
  { value: 'playsuits', key: 'EU 56', label: 'EU 56' },
  { value: 'playsuits', key: 'EU 58', label: 'EU 58' },
  { value: 'playsuits', key: 'W23', label: 'W23' },
  { value: 'playsuits', key: 'W25', label: 'W25' },
  { value: 'playsuits', key: 'W27', label: 'W27' },
  { value: 'playsuits', key: 'W29', label: 'W29' },
  { value: 'playsuits', key: 'W31', label: 'W31' },
  { value: 'playsuits', key: 'W33', label: 'W33' },
  { value: 'playsuits', key: 'US 0', label: 'US 0' },
  { value: 'playsuits', key: 'US 2', label: 'US 2' },
  { value: 'playsuits', key: 'US 4', label: 'US 4' },
  { value: 'playsuits', key: 'US 6', label: 'US 6' },
  { value: 'playsuits', key: 'US 8', label: 'US 8' },
  { value: 'playsuits', key: 'US 10', label: 'US 10' },
  { value: 'playsuits', key: 'US 12', label: 'US 12' },
  { value: 'playsuits', key: 'US 14', label: 'US 14' },
  { value: 'playsuits', key: 'US 16', label: 'US 16' },
  { value: 'playsuits', key: 'US 18', label: 'US 18' },
  { value: 'playsuits', key: 'US 20', label: 'US 20' },
  { value: 'playsuits', key: 'US 22', label: 'US 22' },
  { value: 'playsuits', key: 'US 24', label: 'US 24' },
  { value: 'playsuits', key: 'US 26', label: 'US 26' },
  { value: 'playsuits', key: 'US 28', label: 'US 28' },
  { value: 'playsuits', key: 'US 30', label: 'US 30' },
  { value: 'jumpsuits', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'jumpsuits', key: 'XXS', label: 'XXS' },
  { value: 'jumpsuits', key: 'XS', label: 'XS' },
  { value: 'jumpsuits', key: 'XS/S', label: 'XS/S' },
  { value: 'jumpsuits', key: 'S', label: 'S' },
  { value: 'jumpsuits', key: 'S/M', label: 'S/M' },
  { value: 'jumpsuits', key: 'M', label: 'M' },
  { value: 'jumpsuits', key: 'M/L', label: 'M/L' },
  { value: 'jumpsuits', key: 'L', label: 'L' },
  { value: 'jumpsuits', key: 'L/XL', label: 'L/XL' },
  { value: 'jumpsuits', key: 'XL', label: 'XL' },
  { value: 'jumpsuits', key: 'XXL', label: 'XXL' },
  { value: 'jumpsuits', key: 'XXXL', label: 'XXXL' },
  { value: 'jumpsuits', key: 'EU 30', label: 'EU 30' },
  { value: 'jumpsuits', key: 'EU 32', label: 'EU 32' },
  { value: 'jumpsuits', key: 'EU 34', label: 'EU 34' },
  { value: 'jumpsuits', key: 'EU 36', label: 'EU 36' },
  { value: 'jumpsuits', key: 'EU 38', label: 'EU 38' },
  { value: 'jumpsuits', key: 'EU 40', label: 'EU 40' },
  { value: 'jumpsuits', key: 'EU 42', label: 'EU 42' },
  { value: 'jumpsuits', key: 'EU 44', label: 'EU 44' },
  { value: 'jumpsuits', key: 'EU 46', label: 'EU 46' },
  { value: 'jumpsuits', key: 'EU 48', label: 'EU 48' },
  { value: 'jumpsuits', key: 'EU 50', label: 'EU 50' },
  { value: 'jumpsuits', key: 'EU 52', label: 'EU 52' },
  { value: 'jumpsuits', key: 'EU 54', label: 'EU 54' },
  { value: 'jumpsuits', key: 'EU 56', label: 'EU 56' },
  { value: 'jumpsuits', key: 'EU 58', label: 'EU 58' },
  { value: 'jumpsuits', key: 'W23', label: 'W23' },
  { value: 'jumpsuits', key: 'W25', label: 'W25' },
  { value: 'jumpsuits', key: 'W27', label: 'W27' },
  { value: 'jumpsuits', key: 'W29', label: 'W29' },
  { value: 'jumpsuits', key: 'W31', label: 'W31' },
  { value: 'jumpsuits', key: 'W33', label: 'W33' },
  { value: 'jumpsuits', key: 'US 0', label: 'US 0' },
  { value: 'jumpsuits', key: 'US 2', label: 'US 2' },
  { value: 'jumpsuits', key: 'US 4', label: 'US 4' },
  { value: 'jumpsuits', key: 'US 6', label: 'US 6' },
  { value: 'jumpsuits', key: 'US 8', label: 'US 8' },
  { value: 'jumpsuits', key: 'US 10', label: 'US 10' },
  { value: 'jumpsuits', key: 'US 12', label: 'US 12' },
  { value: 'jumpsuits', key: 'US 14', label: 'US 14' },
  { value: 'jumpsuits', key: 'US 16', label: 'US 16' },
  { value: 'jumpsuits', key: 'US 18', label: 'US 18' },
  { value: 'jumpsuits', key: 'US 20', label: 'US 20' },
  { value: 'jumpsuits', key: 'US 22', label: 'US 22' },
  { value: 'jumpsuits', key: 'US 24', label: 'US 24' },
  { value: 'jumpsuits', key: 'US 26', label: 'US 26' },
  { value: 'jumpsuits', key: 'US 28', label: 'US 28' },
  { value: 'jumpsuits', key: 'US 30', label: 'US 30' },
  { value: 'jumpsuits', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'jumpsuits', key: 'XXS', label: 'XXS' },
  { value: 'jumpsuits', key: 'XS', label: 'XS' },
  { value: 'jumpsuits', key: 'XS/S', label: 'XS/S' },
  { value: 'jumpsuits', key: 'S', label: 'S' },
  { value: 'jumpsuits', key: 'S/M', label: 'S/M' },
  { value: 'jumpsuits', key: 'M', label: 'M' },
  { value: 'jumpsuits', key: 'M/L', label: 'M/L' },
  { value: 'jumpsuits', key: 'L', label: 'L' },
  { value: 'jumpsuits', key: 'L/XL', label: 'L/XL' },
  { value: 'jumpsuits', key: 'XL', label: 'XL' },
  { value: 'jumpsuits', key: 'XXL', label: 'XXL' },
  { value: 'jumpsuits', key: 'XXXL', label: 'XXXL' },
  { value: 'jumpsuits', key: 'EU 30', label: 'EU 30' },
  { value: 'jumpsuits', key: 'EU 32', label: 'EU 32' },
  { value: 'jumpsuits', key: 'EU 34', label: 'EU 34' },
  { value: 'jumpsuits', key: 'EU 36', label: 'EU 36' },
  { value: 'jumpsuits', key: 'EU 38', label: 'EU 38' },
  { value: 'jumpsuits', key: 'EU 40', label: 'EU 40' },
  { value: 'jumpsuits', key: 'EU 42', label: 'EU 42' },
  { value: 'jumpsuits', key: 'EU 44', label: 'EU 44' },
  { value: 'jumpsuits', key: 'EU 46', label: 'EU 46' },
  { value: 'jumpsuits', key: 'EU 48', label: 'EU 48' },
  { value: 'jumpsuits', key: 'EU 50', label: 'EU 50' },
  { value: 'jumpsuits', key: 'EU 52', label: 'EU 52' },
  { value: 'jumpsuits', key: 'EU 54', label: 'EU 54' },
  { value: 'jumpsuits', key: 'EU 56', label: 'EU 56' },
  { value: 'jumpsuits', key: 'EU 58', label: 'EU 58' },
  { value: 'jumpsuits', key: 'W23', label: 'W23' },
  { value: 'jumpsuits', key: 'W25', label: 'W25' },
  { value: 'jumpsuits', key: 'W27', label: 'W27' },
  { value: 'jumpsuits', key: 'W29', label: 'W29' },
  { value: 'jumpsuits', key: 'W31', label: 'W31' },
  { value: 'jumpsuits', key: 'W33', label: 'W33' },
  { value: 'jumpsuits', key: 'US 0', label: 'US 0' },
  { value: 'jumpsuits', key: 'US 2', label: 'US 2' },
  { value: 'jumpsuits', key: 'US 4', label: 'US 4' },
  { value: 'jumpsuits', key: 'US 6', label: 'US 6' },
  { value: 'jumpsuits', key: 'US 8', label: 'US 8' },
  { value: 'jumpsuits', key: 'US 10', label: 'US 10' },
  { value: 'jumpsuits', key: 'US 12', label: 'US 12' },
  { value: 'jumpsuits', key: 'US 14', label: 'US 14' },
  { value: 'jumpsuits', key: 'US 16', label: 'US 16' },
  { value: 'jumpsuits', key: 'US 18', label: 'US 18' },
  { value: 'jumpsuits', key: 'US 20', label: 'US 20' },
  { value: 'jumpsuits', key: 'US 22', label: 'US 22' },
  { value: 'jumpsuits', key: 'US 24', label: 'US 24' },
  { value: 'jumpsuits', key: 'US 26', label: 'US 26' },
  { value: 'jumpsuits', key: 'US 28', label: 'US 28' },
  { value: 'jumpsuits', key: 'US 30', label: 'US 30' },

  { value: 'coats', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'coats', key: 'XXS', label: 'XXS' },
  { value: 'coats', key: 'XS', label: 'XS' },
  { value: 'coats', key: 'XS/S', label: 'XS/S' },
  { value: 'coats', key: 'S', label: 'S' },
  { value: 'coats', key: 'S/M', label: 'S/M' },
  { value: 'coats', key: 'M', label: 'M' },
  { value: 'coats', key: 'M/L', label: 'M/L' },
  { value: 'coats', key: 'L', label: 'L' },
  { value: 'coats', key: 'L/XL', label: 'L/XL' },
  { value: 'coats', key: 'XL', label: 'XL' },
  { value: 'coats', key: 'XXL', label: 'XXL' },
  { value: 'coats', key: 'XXXL', label: 'XXXL' },
  { value: 'coats', key: 'EU 30', label: 'EU 30' },
  { value: 'coats', key: 'EU 32', label: 'EU 32' },
  { value: 'coats', key: 'EU 34', label: 'EU 34' },
  { value: 'coats', key: 'EU 36', label: 'EU 36' },
  { value: 'coats', key: 'EU 38', label: 'EU 38' },
  { value: 'coats', key: 'EU 40', label: 'EU 40' },
  { value: 'coats', key: 'EU 42', label: 'EU 42' },
  { value: 'coats', key: 'EU 44', label: 'EU 44' },
  { value: 'coats', key: 'EU 46', label: 'EU 46' },
  { value: 'coats', key: 'EU 48', label: 'EU 48' },
  { value: 'coats', key: 'EU 50', label: 'EU 50' },
  { value: 'coats', key: 'EU 52', label: 'EU 52' },
  { value: 'coats', key: 'EU 54', label: 'EU 54' },
  { value: 'coats', key: 'EU 56', label: 'EU 56' },
  { value: 'coats', key: 'EU 58', label: 'EU 58' },
  { value: 'coats', key: 'W23', label: 'W23' },
  { value: 'coats', key: 'W25', label: 'W25' },
  { value: 'coats', key: 'W27', label: 'W27' },
  { value: 'coats', key: 'W29', label: 'W29' },
  { value: 'coats', key: 'W31', label: 'W31' },
  { value: 'coats', key: 'W33', label: 'W33' },
  { value: 'coats', key: 'US 0', label: 'US 0' },
  { value: 'coats', key: 'US 2', label: 'US 2' },
  { value: 'coats', key: 'US 4', label: 'US 4' },
  { value: 'coats', key: 'US 6', label: 'US 6' },
  { value: 'coats', key: 'US 8', label: 'US 8' },
  { value: 'coats', key: 'US 10', label: 'US 10' },
  { value: 'coats', key: 'US 12', label: 'US 12' },
  { value: 'coats', key: 'US 14', label: 'US 14' },
  { value: 'coats', key: 'US 16', label: 'US 16' },
  { value: 'coats', key: 'US 18', label: 'US 18' },
  { value: 'coats', key: 'US 20', label: 'US 20' },
  { value: 'coats', key: 'US 22', label: 'US 22' },
  { value: 'coats', key: 'US 24', label: 'US 24' },
  { value: 'coats', key: 'US 26', label: 'US 26' },
  { value: 'coats', key: 'US 28', label: 'US 28' },
  { value: 'coats', key: 'US 30', label: 'US 30' },
  { value: 'coats', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'coats', key: 'XXS', label: 'XXS' },
  { value: 'coats', key: 'XS', label: 'XS' },
  { value: 'coats', key: 'XS/S', label: 'XS/S' },
  { value: 'coats', key: 'S', label: 'S' },
  { value: 'coats', key: 'S/M', label: 'S/M' },
  { value: 'coats', key: 'M', label: 'M' },
  { value: 'coats', key: 'M/L', label: 'M/L' },
  { value: 'coats', key: 'L', label: 'L' },
  { value: 'coats', key: 'L/XL', label: 'L/XL' },
  { value: 'coats', key: 'XL', label: 'XL' },
  { value: 'coats', key: 'XXL', label: 'XXL' },
  { value: 'coats', key: 'XXXL', label: 'XXXL' },
  { value: 'coats', key: 'EU 30', label: 'EU 30' },
  { value: 'coats', key: 'EU 32', label: 'EU 32' },
  { value: 'coats', key: 'EU 34', label: 'EU 34' },
  { value: 'coats', key: 'EU 36', label: 'EU 36' },
  { value: 'coats', key: 'EU 38', label: 'EU 38' },
  { value: 'coats', key: 'EU 40', label: 'EU 40' },
  { value: 'coats', key: 'EU 42', label: 'EU 42' },
  { value: 'coats', key: 'EU 44', label: 'EU 44' },
  { value: 'coats', key: 'EU 46', label: 'EU 46' },
  { value: 'coats', key: 'EU 48', label: 'EU 48' },
  { value: 'coats', key: 'EU 50', label: 'EU 50' },
  { value: 'coats', key: 'EU 52', label: 'EU 52' },
  { value: 'coats', key: 'EU 54', label: 'EU 54' },
  { value: 'coats', key: 'EU 56', label: 'EU 56' },
  { value: 'coats', key: 'EU 58', label: 'EU 58' },
  { value: 'coats', key: 'W23', label: 'W23' },
  { value: 'coats', key: 'W25', label: 'W25' },
  { value: 'coats', key: 'W27', label: 'W27' },
  { value: 'coats', key: 'W29', label: 'W29' },
  { value: 'coats', key: 'W31', label: 'W31' },
  { value: 'coats', key: 'W33', label: 'W33' },
  { value: 'coats', key: 'US 0', label: 'US 0' },
  { value: 'coats', key: 'US 2', label: 'US 2' },
  { value: 'coats', key: 'US 4', label: 'US 4' },
  { value: 'coats', key: 'US 6', label: 'US 6' },
  { value: 'coats', key: 'US 8', label: 'US 8' },
  { value: 'coats', key: 'US 10', label: 'US 10' },
  { value: 'coats', key: 'US 12', label: 'US 12' },
  { value: 'coats', key: 'US 14', label: 'US 14' },
  { value: 'coats', key: 'US 16', label: 'US 16' },
  { value: 'coats', key: 'US 18', label: 'US 18' },
  { value: 'coats', key: 'US 20', label: 'US 20' },
  { value: 'coats', key: 'US 22', label: 'US 22' },
  { value: 'coats', key: 'US 24', label: 'US 24' },
  { value: 'coats', key: 'US 26', label: 'US 26' },
  { value: 'coats', key: 'US 28', label: 'US 28' },
  { value: 'coats', key: 'US 30', label: 'US 30' },
  { value: 'suits', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'suits', key: 'XXS', label: 'XXS' },
  { value: 'suits', key: 'XS', label: 'XS' },
  { value: 'suits', key: 'XS/S', label: 'XS/S' },
  { value: 'suits', key: 'S', label: 'S' },
  { value: 'suits', key: 'S/M', label: 'S/M' },
  { value: 'suits', key: 'M', label: 'M' },
  { value: 'suits', key: 'M/L', label: 'M/L' },
  { value: 'suits', key: 'L', label: 'L' },
  { value: 'suits', key: 'L/XL', label: 'L/XL' },
  { value: 'suits', key: 'XL', label: 'XL' },
  { value: 'suits', key: 'XXL', label: 'XXL' },
  { value: 'suits', key: 'XXXL', label: 'XXXL' },
  { value: 'suits', key: 'EU 30', label: 'EU 30' },
  { value: 'suits', key: 'EU 32', label: 'EU 32' },
  { value: 'suits', key: 'EU 34', label: 'EU 34' },
  { value: 'suits', key: 'EU 36', label: 'EU 36' },
  { value: 'suits', key: 'EU 38', label: 'EU 38' },
  { value: 'suits', key: 'EU 40', label: 'EU 40' },
  { value: 'suits', key: 'EU 42', label: 'EU 42' },
  { value: 'suits', key: 'EU 44', label: 'EU 44' },
  { value: 'suits', key: 'EU 46', label: 'EU 46' },
  { value: 'suits', key: 'EU 48', label: 'EU 48' },
  { value: 'suits', key: 'EU 50', label: 'EU 50' },
  { value: 'suits', key: 'EU 52', label: 'EU 52' },
  { value: 'suits', key: 'EU 54', label: 'EU 54' },
  { value: 'suits', key: 'EU 56', label: 'EU 56' },
  { value: 'suits', key: 'EU 58', label: 'EU 58' },
  { value: 'suits', key: 'W23', label: 'W23' },
  { value: 'suits', key: 'W25', label: 'W25' },
  { value: 'suits', key: 'W27', label: 'W27' },
  { value: 'suits', key: 'W29', label: 'W29' },
  { value: 'suits', key: 'W31', label: 'W31' },
  { value: 'suits', key: 'W33', label: 'W33' },
  { value: 'suits', key: 'US 0', label: 'US 0' },
  { value: 'suits', key: 'US 2', label: 'US 2' },
  { value: 'suits', key: 'US 4', label: 'US 4' },
  { value: 'suits', key: 'US 6', label: 'US 6' },
  { value: 'suits', key: 'US 8', label: 'US 8' },
  { value: 'suits', key: 'US 10', label: 'US 10' },
  { value: 'suits', key: 'US 12', label: 'US 12' },
  { value: 'suits', key: 'US 14', label: 'US 14' },
  { value: 'suits', key: 'US 16', label: 'US 16' },
  { value: 'suits', key: 'US 18', label: 'US 18' },
  { value: 'suits', key: 'US 20', label: 'US 20' },
  { value: 'suits', key: 'US 22', label: 'US 22' },
  { value: 'suits', key: 'US 24', label: 'US 24' },
  { value: 'suits', key: 'US 26', label: 'US 26' },
  { value: 'suits', key: 'US 28', label: 'US 28' },
  { value: 'suits', key: 'US 30', label: 'US 30' },
  { value: 'suits', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'suits', key: 'XXS', label: 'XXS' },
  { value: 'suits', key: 'XS', label: 'XS' },
  { value: 'suits', key: 'XS/S', label: 'XS/S' },
  { value: 'suits', key: 'S', label: 'S' },
  { value: 'suits', key: 'S/M', label: 'S/M' },
  { value: 'suits', key: 'M', label: 'M' },
  { value: 'suits', key: 'M/L', label: 'M/L' },
  { value: 'suits', key: 'L', label: 'L' },
  { value: 'suits', key: 'L/XL', label: 'L/XL' },
  { value: 'suits', key: 'XL', label: 'XL' },
  { value: 'suits', key: 'XXL', label: 'XXL' },
  { value: 'suits', key: 'XXXL', label: 'XXXL' },
  { value: 'suits', key: 'EU 30', label: 'EU 30' },
  { value: 'suits', key: 'EU 32', label: 'EU 32' },
  { value: 'suits', key: 'EU 34', label: 'EU 34' },
  { value: 'suits', key: 'EU 36', label: 'EU 36' },
  { value: 'suits', key: 'EU 38', label: 'EU 38' },
  { value: 'suits', key: 'EU 40', label: 'EU 40' },
  { value: 'suits', key: 'EU 42', label: 'EU 42' },
  { value: 'suits', key: 'EU 44', label: 'EU 44' },
  { value: 'suits', key: 'EU 46', label: 'EU 46' },
  { value: 'suits', key: 'EU 48', label: 'EU 48' },
  { value: 'suits', key: 'EU 50', label: 'EU 50' },
  { value: 'suits', key: 'EU 52', label: 'EU 52' },
  { value: 'suits', key: 'EU 54', label: 'EU 54' },
  { value: 'suits', key: 'EU 56', label: 'EU 56' },
  { value: 'suits', key: 'EU 58', label: 'EU 58' },
  { value: 'suits', key: 'W23', label: 'W23' },
  { value: 'suits', key: 'W25', label: 'W25' },
  { value: 'suits', key: 'W27', label: 'W27' },
  { value: 'suits', key: 'W29', label: 'W29' },
  { value: 'suits', key: 'W31', label: 'W31' },
  { value: 'suits', key: 'W33', label: 'W33' },
  { value: 'suits', key: 'US 0', label: 'US 0' },
  { value: 'suits', key: 'US 2', label: 'US 2' },
  { value: 'suits', key: 'US 4', label: 'US 4' },
  { value: 'suits', key: 'US 6', label: 'US 6' },
  { value: 'suits', key: 'US 8', label: 'US 8' },
  { value: 'suits', key: 'US 10', label: 'US 10' },
  { value: 'suits', key: 'US 12', label: 'US 12' },
  { value: 'suits', key: 'US 14', label: 'US 14' },
  { value: 'suits', key: 'US 16', label: 'US 16' },
  { value: 'suits', key: 'US 18', label: 'US 18' },
  { value: 'suits', key: 'US 20', label: 'US 20' },
  { value: 'suits', key: 'US 22', label: 'US 22' },
  { value: 'suits', key: 'US 24', label: 'US 24' },
  { value: 'suits', key: 'US 26', label: 'US 26' },
  { value: 'suits', key: 'US 28', label: 'US 28' },
  { value: 'suits', key: 'US 30', label: 'US 30' },
  { value: 'tops', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'tops', key: 'XXS', label: 'XXS' },
  { value: 'tops', key: 'XS', label: 'XS' },
  { value: 'tops', key: 'XS/S', label: 'XS/S' },
  { value: 'tops', key: 'S', label: 'S' },
  { value: 'tops', key: 'S/M', label: 'S/M' },
  { value: 'tops', key: 'M', label: 'M' },
  { value: 'tops', key: 'M/L', label: 'M/L' },
  { value: 'tops', key: 'L', label: 'L' },
  { value: 'tops', key: 'L/XL', label: 'L/XL' },
  { value: 'tops', key: 'XL', label: 'XL' },
  { value: 'tops', key: 'XXL', label: 'XXL' },
  { value: 'tops', key: 'XXXL', label: 'XXXL' },
  { value: 'tops', key: 'EU 30', label: 'EU 30' },
  { value: 'tops', key: 'EU 32', label: 'EU 32' },
  { value: 'tops', key: 'EU 34', label: 'EU 34' },
  { value: 'tops', key: 'EU 36', label: 'EU 36' },
  { value: 'tops', key: 'EU 38', label: 'EU 38' },
  { value: 'tops', key: 'EU 40', label: 'EU 40' },
  { value: 'tops', key: 'EU 42', label: 'EU 42' },
  { value: 'tops', key: 'EU 44', label: 'EU 44' },
  { value: 'tops', key: 'EU 46', label: 'EU 46' },
  { value: 'tops', key: 'EU 48', label: 'EU 48' },
  { value: 'tops', key: 'EU 50', label: 'EU 50' },
  { value: 'tops', key: 'EU 52', label: 'EU 52' },
  { value: 'tops', key: 'EU 54', label: 'EU 54' },
  { value: 'tops', key: 'EU 56', label: 'EU 56' },
  { value: 'tops', key: 'EU 58', label: 'EU 58' },
  { value: 'tops', key: 'W23', label: 'W23' },
  { value: 'tops', key: 'W25', label: 'W25' },
  { value: 'tops', key: 'W27', label: 'W27' },
  { value: 'tops', key: 'W29', label: 'W29' },
  { value: 'tops', key: 'W31', label: 'W31' },
  { value: 'tops', key: 'W33', label: 'W33' },
  { value: 'tops', key: 'US 0', label: 'US 0' },
  { value: 'tops', key: 'US 2', label: 'US 2' },
  { value: 'tops', key: 'US 4', label: 'US 4' },
  { value: 'tops', key: 'US 6', label: 'US 6' },
  { value: 'tops', key: 'US 8', label: 'US 8' },
  { value: 'tops', key: 'US 10', label: 'US 10' },
  { value: 'tops', key: 'US 12', label: 'US 12' },
  { value: 'tops', key: 'US 14', label: 'US 14' },
  { value: 'tops', key: 'US 16', label: 'US 16' },
  { value: 'tops', key: 'US 18', label: 'US 18' },
  { value: 'tops', key: 'US 20', label: 'US 20' },
  { value: 'tops', key: 'US 22', label: 'US 22' },
  { value: 'tops', key: 'US 24', label: 'US 24' },
  { value: 'tops', key: 'US 26', label: 'US 26' },
  { value: 'tops', key: 'US 28', label: 'US 28' },
  { value: 'tops', key: 'US 30', label: 'US 30' },
  { value: 'tops', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'tops', key: 'XXS', label: 'XXS' },
  { value: 'tops', key: 'XS', label: 'XS' },
  { value: 'tops', key: 'XS/S', label: 'XS/S' },
  { value: 'tops', key: 'S', label: 'S' },
  { value: 'tops', key: 'S/M', label: 'S/M' },
  { value: 'tops', key: 'M', label: 'M' },
  { value: 'tops', key: 'M/L', label: 'M/L' },
  { value: 'tops', key: 'L', label: 'L' },
  { value: 'tops', key: 'L/XL', label: 'L/XL' },
  { value: 'tops', key: 'XL', label: 'XL' },
  { value: 'tops', key: 'XXL', label: 'XXL' },
  { value: 'tops', key: 'XXXL', label: 'XXXL' },
  { value: 'tops', key: 'EU 30', label: 'EU 30' },
  { value: 'tops', key: 'EU 32', label: 'EU 32' },
  { value: 'tops', key: 'EU 34', label: 'EU 34' },
  { value: 'tops', key: 'EU 36', label: 'EU 36' },
  { value: 'tops', key: 'EU 38', label: 'EU 38' },
  { value: 'tops', key: 'EU 40', label: 'EU 40' },
  { value: 'tops', key: 'EU 42', label: 'EU 42' },
  { value: 'tops', key: 'EU 44', label: 'EU 44' },
  { value: 'tops', key: 'EU 46', label: 'EU 46' },
  { value: 'tops', key: 'EU 48', label: 'EU 48' },
  { value: 'tops', key: 'EU 50', label: 'EU 50' },
  { value: 'tops', key: 'EU 52', label: 'EU 52' },
  { value: 'tops', key: 'EU 54', label: 'EU 54' },
  { value: 'tops', key: 'EU 56', label: 'EU 56' },
  { value: 'tops', key: 'EU 58', label: 'EU 58' },
  { value: 'tops', key: 'W23', label: 'W23' },
  { value: 'tops', key: 'W25', label: 'W25' },
  { value: 'tops', key: 'W27', label: 'W27' },
  { value: 'tops', key: 'W29', label: 'W29' },
  { value: 'tops', key: 'W31', label: 'W31' },
  { value: 'tops', key: 'W33', label: 'W33' },
  { value: 'tops', key: 'US 0', label: 'US 0' },
  { value: 'tops', key: 'US 2', label: 'US 2' },
  { value: 'tops', key: 'US 4', label: 'US 4' },
  { value: 'tops', key: 'US 6', label: 'US 6' },
  { value: 'tops', key: 'US 8', label: 'US 8' },
  { value: 'tops', key: 'US 10', label: 'US 10' },
  { value: 'tops', key: 'US 12', label: 'US 12' },
  { value: 'tops', key: 'US 14', label: 'US 14' },
  { value: 'tops', key: 'US 16', label: 'US 16' },
  { value: 'tops', key: 'US 18', label: 'US 18' },
  { value: 'tops', key: 'US 20', label: 'US 20' },
  { value: 'tops', key: 'US 22', label: 'US 22' },
  { value: 'tops', key: 'US 24', label: 'US 24' },
  { value: 'tops', key: 'US 26', label: 'US 26' },
  { value: 'tops', key: 'US 28', label: 'US 28' },
  { value: 'tops', key: 'US 30', label: 'US 30' },
  { value: 'skirts', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'skirts', key: 'XXS', label: 'XXS' },
  { value: 'skirts', key: 'XS', label: 'XS' },
  { value: 'skirts', key: 'XS/S', label: 'XS/S' },
  { value: 'skirts', key: 'S', label: 'S' },
  { value: 'skirts', key: 'S/M', label: 'S/M' },
  { value: 'skirts', key: 'M', label: 'M' },
  { value: 'skirts', key: 'M/L', label: 'M/L' },
  { value: 'skirts', key: 'L', label: 'L' },
  { value: 'skirts', key: 'L/XL', label: 'L/XL' },
  { value: 'skirts', key: 'XL', label: 'XL' },
  { value: 'skirts', key: 'XXL', label: 'XXL' },
  { value: 'skirts', key: 'XXXL', label: 'XXXL' },
  { value: 'skirts', key: 'EU 30', label: 'EU 30' },
  { value: 'skirts', key: 'EU 32', label: 'EU 32' },
  { value: 'skirts', key: 'EU 34', label: 'EU 34' },
  { value: 'skirts', key: 'EU 36', label: 'EU 36' },
  { value: 'skirts', key: 'EU 38', label: 'EU 38' },
  { value: 'skirts', key: 'EU 40', label: 'EU 40' },
  { value: 'skirts', key: 'EU 42', label: 'EU 42' },
  { value: 'skirts', key: 'EU 44', label: 'EU 44' },
  { value: 'skirts', key: 'EU 46', label: 'EU 46' },
  { value: 'skirts', key: 'EU 48', label: 'EU 48' },
  { value: 'skirts', key: 'EU 50', label: 'EU 50' },
  { value: 'skirts', key: 'EU 52', label: 'EU 52' },
  { value: 'skirts', key: 'EU 54', label: 'EU 54' },
  { value: 'skirts', key: 'EU 56', label: 'EU 56' },
  { value: 'skirts', key: 'EU 58', label: 'EU 58' },
  { value: 'skirts', key: 'W23', label: 'W23' },
  { value: 'skirts', key: 'W25', label: 'W25' },
  { value: 'skirts', key: 'W27', label: 'W27' },
  { value: 'skirts', key: 'W29', label: 'W29' },
  { value: 'skirts', key: 'W31', label: 'W31' },
  { value: 'skirts', key: 'W33', label: 'W33' },
  { value: 'skirts', key: 'US 0', label: 'US 0' },
  { value: 'skirts', key: 'US 2', label: 'US 2' },
  { value: 'skirts', key: 'US 4', label: 'US 4' },
  { value: 'skirts', key: 'US 6', label: 'US 6' },
  { value: 'skirts', key: 'US 8', label: 'US 8' },
  { value: 'skirts', key: 'US 10', label: 'US 10' },
  { value: 'skirts', key: 'US 12', label: 'US 12' },
  { value: 'skirts', key: 'US 14', label: 'US 14' },
  { value: 'skirts', key: 'US 16', label: 'US 16' },
  { value: 'skirts', key: 'US 18', label: 'US 18' },
  { value: 'skirts', key: 'US 20', label: 'US 20' },
  { value: 'skirts', key: 'US 22', label: 'US 22' },
  { value: 'skirts', key: 'US 24', label: 'US 24' },
  { value: 'skirts', key: 'US 26', label: 'US 26' },
  { value: 'skirts', key: 'US 28', label: 'US 28' },
  { value: 'skirts', key: 'US 30', label: 'US 30' },

  { value: 'dresses', key: 'ONE SIZE', label: 'ONE SIZE' },
  { value: 'dresses', key: 'XXS', label: 'XXS' },
  { value: 'dresses', key: 'XS', label: 'XS' },
  { value: 'dresses', key: 'XS/S', label: 'XS/S' },
  { value: 'dresses', key: 'S', label: 'S' },
  { value: 'dresses', key: 'S/M', label: 'S/M' },
  { value: 'dresses', key: 'M', label: 'M' },
  { value: 'dresses', key: 'M/L', label: 'M/L' },
  { value: 'dresses', key: 'L', label: 'L' },
  { value: 'dresses', key: 'L/XL', label: 'L/XL' },
  { value: 'dresses', key: 'XL', label: 'XL' },
  { value: 'dresses', key: 'XXL', label: 'XXL' },
  { value: 'dresses', key: 'XXXL', label: 'XXXL' },
  { value: 'dresses', key: 'EU 30', label: 'EU 30' },
  { value: 'dresses', key: 'EU 32', label: 'EU 32' },
  { value: 'dresses', key: 'EU 34', label: 'EU 34' },
  { value: 'dresses', key: 'EU 36', label: 'EU 36' },
  { value: 'dresses', key: 'EU 38', label: 'EU 38' },
  { value: 'dresses', key: 'EU 40', label: 'EU 40' },
  { value: 'dresses', key: 'EU 42', label: 'EU 42' },
  { value: 'dresses', key: 'EU 44', label: 'EU 44' },
  { value: 'dresses', key: 'EU 46', label: 'EU 46' },
  { value: 'dresses', key: 'EU 48', label: 'EU 48' },
  { value: 'dresses', key: 'EU 50', label: 'EU 50' },
  { value: 'dresses', key: 'EU 52', label: 'EU 52' },
  { value: 'dresses', key: 'EU 54', label: 'EU 54' },
  { value: 'dresses', key: 'EU 56', label: 'EU 56' },
  { value: 'dresses', key: 'EU 58', label: 'EU 58' },
  { value: 'dresses', key: 'W23', label: 'W23' },
  { value: 'dresses', key: 'W25', label: 'W25' },
  { value: 'dresses', key: 'W27', label: 'W27' },
  { value: 'dresses', key: 'W29', label: 'W29' },
  { value: 'dresses', key: 'W31', label: 'W31' },
  { value: 'dresses', key: 'W33', label: 'W33' },
  { value: 'dresses', key: 'US 0', label: 'US 0' },
  { value: 'dresses', key: 'US 2', label: 'US 2' },
  { value: 'dresses', key: 'US 4', label: 'US 4' },
  { value: 'dresses', key: 'US 6', label: 'US 6' },
  { value: 'dresses', key: 'US 8', label: 'US 8' },
  { value: 'dresses', key: 'US 10', label: 'US 10' },
  { value: 'dresses', key: 'US 12', label: 'US 12' },
  { value: 'dresses', key: 'US 14', label: 'US 14' },
  { value: 'dresses', key: 'US 16', label: 'US 16' },
  { value: 'dresses', key: 'US 18', label: 'US 18' },
  { value: 'dresses', key: 'US 20', label: 'US 20' },
  { value: 'dresses', key: 'US 22', label: 'US 22' },
  { value: 'dresses', key: 'US 24', label: 'US 24' },
  { value: 'dresses', key: 'US 26', label: 'US 26' },
  { value: 'dresses', key: 'US 28', label: 'US 28' },
  { value: 'dresses', key: 'US 30', label: 'US 30' },
  { value: 'dresses', key: 'OTHER', label: 'OTHER' },
  { value: 'trouser_jeans', key: 'UK 2', label: 'UK 2' },
  { value: 'trouser_jeans', key: 'UK 4', label: 'UK 4' },
  { value: 'trouser_jeans', key: 'UK 6', label: 'UK 6' },
  { value: 'trouser_jeans', key: 'UK 8', label: 'UK 8' },
  { value: 'trouser_jeans', key: 'UK 10', label: 'UK 10' },
  { value: 'trouser_jeans', key: 'UK 12', label: 'UK 12' },
  { value: 'trouser_jeans', key: 'UK 14', label: 'UK 14' },
  { value: 'trouser_jeans', key: 'UK 16', label: 'UK 16' },
  { value: 'trouser_jeans', key: 'UK 18', label: 'UK 18' },
  { value: 'trouser_jeans', key: 'UK 20', label: 'UK 20' },
  { value: 'trouser_jeans', key: 'UK 22', label: 'UK 22' },
  { value: 'trouser_jeans', key: 'UK 24', label: 'UK 24' },
  { value: 'trouser_jeans', key: 'UK 26', label: 'UK 26' },
  { value: 'trouser_jeans', key: 'UK 28', label: 'UK 28' },
  { value: 'trouser_jeans', key: 'UK 30', label: 'UK 30' },
  { value: 'trouser_jeans', key: 'XXS', label: 'XXS' },
  { value: 'trouser_jeans', key: 'XS', label: 'XS' },
  { value: 'trouser_jeans', key: 'XS/S', label: 'XS/S' },
  { value: 'trouser_jeans', key: 'S', label: 'S' },
  { value: 'trouser_jeans', key: 'S/M', label: 'S/M' },
  { value: 'trouser_jeans', key: 'M', label: 'M' },
  { value: 'trouser_jeans', key: 'M/L', label: 'M/L' },
  { value: 'trouser_jeans', key: 'L', label: 'L' },
  { value: 'trouser_jeans', key: 'L/XL', label: 'L/XL' },
  { value: 'trouser_jeans', key: 'XL', label: 'XL' },
  { value: 'trouser_jeans', key: 'XXL', label: 'XXL' },
  { value: 'trouser_jeans', key: 'XXXL', label: 'XXXL' },
  { value: 'trouser_jeans', key: 'EU 30', label: 'EU 30' },
  { value: 'trouser_jeans', key: 'EU 32', label: 'EU 32' },
  { value: 'trouser_jeans', key: 'EU 34', label: 'EU 34' },
  { value: 'trouser_jeans', key: 'EU 36', label: 'EU 36' },
  { value: 'trouser_jeans', key: 'EU 38', label: 'EU 38' },
  { value: 'trouser_jeans', key: 'EU 40', label: 'EU 40' },
  { value: 'trouser_jeans', key: 'EU 42', label: 'EU 42' },
  { value: 'trouser_jeans', key: 'EU 44', label: 'EU 44' },
  { value: 'trouser_jeans', key: 'EU 46', label: 'EU 46' },
  { value: 'trouser_jeans', key: 'EU 48', label: 'EU 48' },
  { value: 'trouser_jeans', key: 'EU 50', label: 'EU 50' },
  { value: 'trouser_jeans', key: 'EU 52', label: 'EU 52' },
  { value: 'trouser_jeans', key: 'EU 54', label: 'EU 54' },
  { value: 'trouser_jeans', key: 'EU 56', label: 'EU 56' },
  { value: 'trouser_jeans', key: 'EU 58', label: 'EU 58' },
  { value: 'trouser_jeans', key: 'W23', label: 'W23' },
  { value: 'trouser_jeans', key: 'W25', label: 'W25' },
  { value: 'trouser_jeans', key: 'W27', label: 'W27' },
  { value: 'trouser_jeans', key: 'W29', label: 'W29' },
  { value: 'trouser_jeans', key: 'W31', label: 'W31' },
  { value: 'trouser_jeans', key: 'W33', label: 'W33' },
  { value: 'trouser_jeans', key: 'US 0', label: 'US 0' },
  { value: 'trouser_jeans', key: 'US 2', label: 'US 2' },
  { value: 'trouser_jeans', key: 'US 4', label: 'US 4' },
  { value: 'trouser_jeans', key: 'US 6', label: 'US 6' },
  { value: 'trouser_jeans', key: 'US 8', label: 'US 8' },
  { value: 'trouser_jeans', key: 'US 10', label: 'US 10' },
  { value: 'trouser_jeans', key: 'US 12', label: 'US 12' },
  { value: 'trouser_jeans', key: 'US 14', label: 'US 14' },
  { value: 'trouser_jeans', key: 'US 16', label: 'US 16' },
  { value: 'trouser_jeans', key: 'US 18', label: 'US 18' },
  { value: 'trouser_jeans', key: 'US 20', label: 'US 20' },
  { value: 'trouser_jeans', key: 'US 22', label: 'US 22' },
  { value: 'trouser_jeans', key: 'US 24', label: 'US 24' },
  { value: 'trouser_jeans', key: 'US 26', label: 'US 26' },
  { value: 'trouser_jeans', key: 'US 28', label: 'US 28' },
  { value: 'trouser_jeans', key: 'US 30', label: 'US 30' },
  { value: 'trouser_jeans', key: 'UK 4', label: 'UK 4' },
  { value: 'trouser_jeans', key: 'UK 4 L30', label: 'UK 4 L30' },
  { value: 'trouser_jeans', key: 'UK 4 L32', label: 'UK 4 L32' },
  { value: 'trouser_jeans', key: 'UK 4 L34', label: 'UK 4 L34' },
  { value: 'trouser_jeans', key: 'UK 4 L36', label: 'UK 4 L36' },
  { value: 'trouser_jeans', key: 'UK 6', label: 'UK 6' },
  { value: 'trouser_jeans', key: 'UK6 L30', label: 'UK6 L30' },
  { value: 'trouser_jeans', key: 'UK 6 L32', label: 'UK 6 L32' },
  { value: 'trouser_jeans', key: 'UK 6 L34', label: 'UK 6 L34' },
  { value: 'trouser_jeans', key: 'UK 6 L36', label: 'UK 6 L36' },
  { value: 'trouser_jeans', key: 'UK 6 L34(9)', label: 'UK 6 L34(9)' },
  { value: 'trouser_jeans', key: 'UK 8', label: 'UK 8' },
  { value: 'trouser_jeans', key: 'UK 8 L30', label: 'UK 8 L30' },
  { value: 'trouser_jeans', key: 'UK 8 L32', label: 'UK 8 L32' },
  { value: 'trouser_jeans', key: 'UK 8 L34', label: 'UK 8 L34' },
  { value: 'trouser_jeans', key: 'UK8 L36', label: 'UK8 L36' },
  { value: 'trouser_jeans', key: 'UK 10', label: 'UK 10' },
  { value: 'trouser_jeans', key: 'UK 10 L30', label: 'UK 10 L30' },
  { value: 'trouser_jeans', key: 'UK 10 L32', label: 'UK 10 L32' },
  { value: 'trouser_jeans', key: 'UK 10 L34', label: 'UK 10 L34' },
  { value: 'trouser_jeans', key: 'UK 10 L36', label: 'UK 10 L36' },
  { value: 'trouser_jeans', key: 'UK 12', label: 'UK 12' },
  { value: 'trouser_jeans', key: 'UK12 L30', label: 'UK12 L30' },
  { value: 'trouser_jeans', key: 'UK 12 L32', label: 'UK 12 L32' },
  { value: 'trouser_jeans', key: 'UK 12 L34', label: 'UK 12 L34' },
  { value: 'trouser_jeans', key: 'UK 12 L36', label: 'UK 12 L36' },
  { value: 'trouser_jeans', key: 'UK 14', label: 'UK 14' },
  { value: 'trouser_jeans', key: 'UK 14 L30', label: 'UK 14 L30' },
  { value: 'trouser_jeans', key: 'UK 14 L32', label: 'UK 14 L32' },
  { value: 'trouser_jeans', key: 'UK 14 L34', label: 'UK 14 L34' },
  { value: 'trouser_jeans', key: 'UK 14 L36', label: 'UK 14 L36' },
  { value: 'trouser_jeans', key: 'UK 16', label: 'UK 16' },
  { value: 'trouser_jeans', key: 'UK 16 L30', label: 'UK 16 L30' },
  { value: 'trouser_jeans', key: 'UK 16 L32', label: 'UK 16 L32' },
  { value: 'trouser_jeans', key: 'UK 16 L34', label: 'UK 16 L34' },
  { value: 'trouser_jeans', key: 'UK 16 L36', label: 'UK 16 L36' },
  { value: 'trouser_jeans', key: 'UK 18', label: 'UK 18' },
  { value: 'trouser_jeans', key: 'UK 18 L30', label: 'UK 18 L30' },
  { value: 'trouser_jeans', key: 'UK 18 L34', label: 'UK 18 L34' },
  { value: 'trouser_jeans', key: 'UK 18 L36', label: 'UK 18 L36' },
  { value: 'trouser_jeans', key: 'UK 20', label: 'UK 20' },
  { value: 'trouser_jeans', key: 'UK 20 L30', label: 'UK 20 L30' },
  { value: 'trouser_jeans', key: 'UK 20 L32', label: 'UK 20 L32' },
  { value: 'trouser_jeans', key: 'UK 20 L34', label: 'UK 20 L34' },
  { value: 'trouser_jeans', key: 'UK 20 L36', label: 'UK 20 L36' },
  { value: 'trouser_jeans', key: 'UK 20-22', label: 'UK 20-22' },
  { value: 'trouser_jeans', key: 'UK 22', label: 'UK 22' },
  { value: 'trouser_jeans', key: 'UK 22 L30', label: 'UK 22 L30' },
  { value: 'trouser_jeans', key: 'UK 22 L32', label: 'UK 22 L32' },
  { value: 'trouser_jeans', key: 'UK 22 L34', label: 'UK 22 L34' },
  { value: 'trouser_jeans', key: 'UK 22 L36', label: 'UK 22 L36' },
  { value: 'trouser_jeans', key: 'UK 24', label: 'UK 24' },
  { value: 'trouser_jeans', key: 'UK24 L30', label: 'UK24 L30' },
  { value: 'trouser_jeans', key: 'UK24 L32', label: 'UK24 L32' },
  { value: 'trouser_jeans', key: 'UK24 L34', label: 'UK24 L34' },
  { value: 'trouser_jeans', key: 'UK 24 L36', label: 'UK 24 L36' },
  { value: 'trouser_jeans', key: 'UK 26', label: 'UK 26' },
  { value: 'trouser_jeans', key: 'UK 28(4)', label: 'UK 28(4)' },
  { value: 'trouser_jeans', key: 'UK28 L34(1)', label: 'UK28 L34(1)' },
  { value: 'trouser_jeans', key: 'W24 L30', label: 'W24 L30' },
  { value: 'trouser_jeans', key: 'W24 L32', label: 'W24 L32' },
  { value: 'trouser_jeans', key: 'W24 L34', label: 'W24 L34' },
  { value: 'trouser_jeans', key: 'W25 L30', label: 'W25 L30' },
  { value: 'trouser_jeans', key: 'W25 L32', label: 'W25 L32' },
  { value: 'trouser_jeans', key: 'W25 L34', label: 'W25 L34' },
  { value: 'trouser_jeans', key: 'W26 L30', label: 'W26 L30' },
  { value: 'trouser_jeans', key: 'W26 L32', label: 'W26 L32' },
  { value: 'trouser_jeans', key: 'W26 L34', label: 'W26 L34' },
  { value: 'trouser_jeans', key: 'W27 L30', label: 'W27 L30' },
  { value: 'trouser_jeans', key: 'W27 L32', label: 'W27 L32' },
  { value: 'trouser_jeans', key: 'W27 L34', label: 'W27 L34' },
  { value: 'trouser_jeans', key: 'W28 L30', label: 'W28 L30' },
  { value: 'trouser_jeans', key: 'W28 L32', label: 'W28 L32' },
  { value: 'trouser_jeans', key: 'W28 L34', label: 'W28 L34' },
  { value: 'trouser_jeans', key: 'W29 L30', label: 'W29 L30' },
  { value: 'trouser_jeans', key: 'W29 L32', label: 'W29 L32' },
  { value: 'trouser_jeans', key: 'W29 L34', label: 'W29 L34' },
  { value: 'trouser_jeans', key: 'W30 L30', label: 'W30 L30' },
  { value: 'trouser_jeans', key: 'W30 L32', label: 'W30 L32' },
  { value: 'trouser_jeans', key: 'W30 L34', label: 'W30 L34' },
  { value: 'trouser_jeans', key: 'W31 L30', label: 'W31 L30' },
  { value: 'trouser_jeans', key: 'W31 L32', label: 'W31 L32' },
  { value: 'trouser_jeans', key: 'W31 L34', label: 'W31 L34' },
  { value: 'trouser_jeans', key: 'W32 L30', label: 'W32 L30' },
  { value: 'trouser_jeans', key: 'W32 L32', label: 'W32 L32' },
  { value: 'trouser_jeans', key: 'W32 L34', label: 'W32 L34' },
  { value: 'trouser_jeans', key: 'W33 L30', label: 'W33 L30' },
  { value: 'trouser_jeans', key: 'W33 L32', label: 'W33 L32' },
  { value: 'trouser_jeans', key: 'W33 L34', label: 'W33 L34' },
  { value: 'trouser_jeans', key: 'W34 L30', label: 'W34 L30' },
  { value: 'trouser_jeans', key: 'W34 L32', label: 'W34 L32' },
  { value: 'trouser_jeans', key: 'W34 L34', label: 'W34 L34' },
  { value: 'trouser_jeans', key: 'OTHER', label: 'OTHER' },
  { value: 'shoes', key: 'UK 2', label: 'UK 2' },
  { value: 'shoes', key: 'UK 2.5', label: 'UK 2.5' },
  { value: 'shoes', key: 'UK 3', label: 'UK 3' },
  { value: 'shoes', key: 'UK 3.5', label: 'UK 3.5' },
  { value: 'shoes', key: 'UK 4', label: 'UK 4' },
  { value: 'shoes', key: 'UK 4.5', label: 'UK 4.5' },
  { value: 'shoes', key: 'UK 5', label: 'UK 5' },
  { value: 'shoes', key: 'UK 5.5', label: 'UK 5.5' },
  { value: 'shoes', key: 'UK 6', label: 'UK 6' },
  { value: 'shoes', key: 'UK 6.5', label: 'UK 6.5' },
  { value: 'shoes', key: 'UK 7', label: 'UK 7' },
  { value: 'shoes', key: 'UK 7.5', label: 'UK 7.5' },
  { value: 'shoes', key: 'UK 8', label: 'UK 8' },
  { value: 'shoes', key: 'UK 8.5', label: 'UK 8.5' },
  { value: 'shoes', key: 'UK 9', label: 'UK 9' },
  { value: 'shoes', key: 'UK 9.5', label: 'UK 9.5' },
  { value: 'shoes', key: 'UK 10', label: 'UK 10' },
  { value: 'shoes', key: 'UK 10.5', label: 'UK 10.5' },
  { value: 'shoes', key: 'UK 11', label: 'UK 11' },
  { value: 'shoes', key: 'UK 11.5', label: 'UK 11.5' },
  { value: 'shoes', key: 'UK 12', label: 'UK 12' },
  { value: 'shoes', key: 'UK 13', label: 'UK 13' },
  { value: 'shoes', key: 'UK 14', label: 'UK 14' },
  { value: 'shoes', key: 'UK 34', label: 'UK 34' },
  { value: 'shoes', key: 'EU 34.5', label: 'EU 34.5' },
  { value: 'shoes', key: 'EU 35', label: 'EU 35' },
  { value: 'shoes', key: 'EU 35.5', label: 'EU 35.5' },
  { value: 'shoes', key: 'EU 36', label: 'EU 36' },
  { value: 'shoes', key: 'EU 36.5', label: 'EU 36.5' },
  { value: 'shoes', key: 'EU 37', label: 'EU 37' },
  { value: 'shoes', key: 'EU 37.5', label: 'EU 37.5' },
  { value: 'shoes', key: 'EU 38', label: 'EU 38' },
  { value: 'shoes', key: 'EU 39', label: 'EU 39' },
  { value: 'shoes', key: 'EU 39.5', label: 'EU 39.5' },
  { value: 'shoes', key: 'EU 40', label: 'EU 40' },
  { value: 'shoes', key: 'EU 40.5', label: 'EU 40.5' },
  { value: 'shoes', key: 'EU 41', label: 'EU 41' },
  { value: 'shoes', key: 'EU 41.5', label: 'EU 41.5' },
  { value: 'shoes', key: 'EU 42', label: 'EU 42' },
  { value: 'shoes', key: 'EU 42.5', label: 'EU 42.5' },
  { value: 'shoes', key: 'EU 43', label: 'EU 43' },
  { value: 'shoes', key: 'EU 43.5', label: 'EU 43.5' },
  { value: 'shoes', key: 'EU 44', label: 'EU 44' },
  { value: 'shoes', key: 'EU 44.5', label: 'EU 44.5' },
  { value: 'shoes', key: 'EU 45', label: 'EU 45' },
  { value: 'shoes', key: 'EU 46', label: 'EU 46' },
  { value: 'shoes', key: 'EU 46.5', label: 'EU 46.5' },
  { value: 'shoes', key: 'EU 47', label: 'EU 47' },
  { value: 'shoes', key: 'EU 47.5', label: 'EU 47.5' },
  { value: 'shoes', key: 'EU 48', label: 'EU 48' },
  { value: 'shoes', key: 'EU 49', label: 'EU 49' },
  { value: 'shoes', key: 'EU 50', label: 'EU 50' },
  { value: 'shoes', key: 'EU 52', label: 'EU 52' },
  { value: 'shoes', key: 'OTHER', label: 'OTHER' },
];

export const colour = [
  { key: 'Black', label: 'Black' },
  { key: 'Blue', label: 'Blue' },
  { key: 'Brown', label: 'Brown' },
  { key: 'Beige', label: 'Beige' },
  { key: 'Bronze', label: 'Bronze' },
  { key: 'Copper', label: 'Copper' },
  { key: 'Coral', label: 'Coral' },
  { key: 'Gold', label: 'Gold' },
  { key: 'Green', label: 'Green' },
  { key: 'Grey', label: 'Grey' },
  { key: 'Multi', label: 'Multi' },
  { key: 'Navy', label: 'Navy' },
  { key: 'Neutral', label: 'Neutral' },
  { key: 'Orange', label: 'Orange' },
  { key: 'Pink', label: 'Pink' },
  { key: 'Purple', label: 'Purple' },
  { key: 'Red', label: 'Red' },
  { key: 'Silver', label: 'Silver' },
  { key: 'White', label: 'White' },
  { key: 'Yellow', label: 'Yellow' },
  { key: 'Lilac', label: 'Lilac' },
  { key: 'Other', label: 'OTHER' },
];

export const signUpWelcome =[
  { key: 'Instagram', label: 'Instagram', value: 'Instagram' },
  { key: 'fromAfriend', label: 'A friend', value: 'A friend' },
  { key: 'fromAmagazine/the press', label: 'Magazine / Press', value: 'Magazine / Press' },
  { key: 'fromAninfluencers', label: 'Influencer', value: 'Influencer' },
  // { key: 'fromOurStore', label: 'from our store', value: 'from our store' },
  { key: 'other', label: 'Other', value: 'Other' },

]
export const brand = [
  { key: 'Buzina', label: 'Buzina', value: 'Buzina' },
  {
    key: '& OTHER STORIES',
    label: '& OTHER STORIES',
    value: '& OTHER STORIES',
  },
  { key: '&DAUGHTER', label: '&DAUGHTER', value: '&DAUGHTER' },
  { key: '(DI)VISION', label: '(DI)VISION', value: '(DI)VISION' },
  { key: '032C', label: '032C', value: '032C' },

  { key: '0711 TBILISI', label: '0711 TBILISI', value: '0711 TBILISI' },
  { key: '1 ET 1 FONT 3', label: '1 ET 1 FONT 3', value: '1 ET 1 FONT 3' },
  { key: '1/0FF', label: '1/0FF', value: '1/0FF' },
  {
    key: '10 CROSBY BY DEREK LAM',
    label: '10 CROSBY BY DEREK LAM',
    value: '10 CROSBY BY DEREK LAM',
  },
  { key: '10 DAYS', label: '10 DAYS', value: '10 DAYS' },
  { key: '10 IS', label: '10 IS', value: '10 IS' },
  { key: '100 HANDS', label: '100 HANDS', value: '100 HANDS' },
  { key: '100% CAPRI', label: '100% CAPRI', value: '100% CAPRI' },
  { key: '1017 ALYX 9SM', label: '1017 ALYX 9SM', value: '1017 ALYX 9SM' },
  { key: '1083', label: '1083', value: '1083' },
  { key: '10SEI0OTTO', label: '10SEI0OTTO', value: '10SEI0OTTO' },
  {
    key: '11 BY BORIS BIDJAN SABERI',
    label: '11 BY BORIS BIDJAN SABERI',
    value: '11 BY BORIS BIDJAN SABERI',
  },
  { key: '12 STOREEZ', label: '12 STOREEZ', value: '12 STOREEZ' },
  { key: '120% LINO', label: '120% LINO', value: '120% LINO' },
  { key: '13 DE MARZO', label: '13 DE MARZO', value: '13 DE MARZO' },
  { key: '16 ARLINGTON', label: '16 ARLINGTON', value: '16 ARLINGTON' },
  { key: '17 SUNDAYS', label: '17 SUNDAYS', value: '17 SUNDAYS' },
  { key: '18TH AMENDMENT', label: '18TH AMENDMENT', value: '18TH AMENDMENT' },
  { key: '1XBLUE', label: '1XBLUE', value: '1XBLUE' },
  { key: '24S.COM', label: '24S.COM', value: '24S.COM' },
  {
    key: '25 LUXURY STREET',
    label: '25 LUXURY STREET',
    value: '25 LUXURY STREET',
  },
  { key: '27 MILES', label: '27 MILES', value: '27 MILES' },
  { key: '2728', label: '2728', value: '2728' },
  { key: '275TH', label: '275TH', value: '275TH' },
  { key: '291 VENICE', label: '291 VENICE', value: '291 VENICE' },
  { key: '2ECOND FLOOR', label: '2ECOND FLOOR', value: '2ECOND FLOOR' },
  { key: '2ND DAY', label: '2ND DAY', value: '2ND DAY' },
  { key: '2XU', label: '2XU', value: '2XU' },
  { key: '3 ANOTHER', label: '3 ANOTHER', value: '3 ANOTHER' },
  {
    key: '3.1 PHILLIP LIM',
    label: '3.1 PHILLIP LIM',
    value: '3.1 PHILLIP LIM',
  },
  { key: '303 AVENUE', label: '303 AVENUE', value: '303 AVENUE' },
  { key: '310 MOOD', label: '310 MOOD', value: '310 MOOD' },
  { key: '313', label: '313', value: '313' },
  {
    key: '32 PARADIS SPRUNG FRERES',
    label: '32 PARADIS SPRUNG FRERES',
    value: '32 PARADIS SPRUNG FRERES',
  },
  { key: '360 CASHMERE', label: '360 CASHMERE', value: '360 CASHMERE' },
  { key: '360 SWEATER', label: '360 SWEATER', value: '360 SWEATER' },
  { key: '3NY', label: '3NY', value: '3NY' },
  { key: '3RD ROOM', label: '3RD ROOM', value: '3RD ROOM' },
  { key: '3X1', label: '3X1', value: '3X1' },
  {
    key: '4 FUNKY FLAVOURS',
    label: '4 FUNKY FLAVOURS',
    value: '4 FUNKY FLAVOURS',
  },
  {
    key: "40'S & SHORTIES",
    label: "40'S & SHORTIES",
    value: "40'S & SHORTIES",
  },
  { key: '424', label: '424', value: '424' },
  { key: '44 LABEL', label: '44 LABEL', value: '44 LABEL' },
  { key: '44 STUDIO', label: '44 STUDIO', value: '44 STUDIO' },
  { key: '45RPM', label: '45RPM', value: '45RPM' },
  { key: '4GIVENESS', label: '4GIVENESS', value: '4GIVENESS' },
  { key: '4KAMINI', label: '4KAMINI', value: '4KAMINI' },
  { key: '4TH & RECKLESS', label: '4TH & RECKLESS', value: '4TH & RECKLESS' },
  { key: '5 OCTOBRE', label: '5 OCTOBRE', value: '5 OCTOBRE' },
  { key: '5 PREVIEW', label: '5 PREVIEW', value: '5 PREVIEW' },
  { key: '5 PROGRESS', label: '5 PROGRESS', value: '5 PROGRESS' },
  { key: '525 AMERICA', label: '525 AMERICA', value: '525 AMERICA' },
  { key: '6397', label: '6397', value: '6397' },
  { key: '66 NORTH', label: '66 NORTH', value: '66 NORTH' },
  { key: '7 DAYS ACTIVE', label: '7 DAYS ACTIVE', value: '7 DAYS ACTIVE' },
  { key: '7 DIAMONDS', label: '7 DIAMONDS', value: '7 DIAMONDS' },
  {
    key: '7 FOR ALL MANKIND',
    label: '7 FOR ALL MANKIND',
    value: '7 FOR ALL MANKIND',
  },
  { key: '711', label: '711', value: '711' },
  { key: '7115 NEW YORK', label: '7115 NEW YORK', value: '7115 NEW YORK' },
  { key: '727SAILBAGS', label: '727SAILBAGS', value: '727SAILBAGS' },
  { key: '7DAYS ACTIVE', label: '7DAYS ACTIVE', value: '7DAYS ACTIVE' },
  { key: '8 BY YOOX', label: '8 BY YOOX', value: '8 BY YOOX' },
  { key: '8-JS', label: '8-JS', value: '8-JS' },
  { key: '81HOURS', label: '81HOURS', value: '81HOURS' },
  { key: '88 & HALF', label: '88 & HALF', value: '88 & HALF' },
  {
    key: '88RISING X GUESS',
    label: '88RISING X GUESS',
    value: '88RISING X GUESS',
  },
  { key: '20:00', label: '20:00', value: '20:00' },
  { key: '8TH SIGN', label: '8TH SIGN', value: '8TH SIGN' },
  { key: '99%IS-', label: '99%IS-', value: '99%IS-' },
  { key: 'BUTTER & HAZEL', label: 'BUTTER & HAZEL', value: 'BUTTER & HAZEL' },
  { key: 'A BATHING APE', label: 'A BATHING APE', value: 'A BATHING APE' },
  {
    key: 'A BETTER FEELING',
    label: 'A BETTER FEELING',
    value: 'A BETTER FEELING',
  },
  {
    key: 'A BETTER MISTAKE',
    label: 'A BETTER MISTAKE',
    value: 'A BETTER MISTAKE',
  },
  { key: 'A BRONZE AGE', label: 'A BRONZE AGE', value: 'A BRONZE AGE' },
  {
    key: 'A CHILD OF THE JAGO',
    label: 'A CHILD OF THE JAGO',
    value: 'A CHILD OF THE JAGO',
  },
  {
    key: 'A CUCKOO MOMENT...',
    label: 'A CUCKOO MOMENT...',
    value: 'A CUCKOO MOMENT...',
  },
  { key: "A DAY'S MARCH", label: "A DAY'S MARCH", value: "A DAY'S MARCH" },
  {
    key: 'A KIND OF GUISE',
    label: 'A KIND OF GUISE',
    value: 'A KIND OF GUISE',
  },
  {
    key: 'A PART OF THE ART',
    label: 'A PART OF THE ART',
    value: 'A PART OF THE ART',
  },
  {
    key: 'A PERSONAL UNIFORM',
    label: 'A PERSONAL UNIFORM',
    value: 'A PERSONAL UNIFORM',
  },
  {
    key: 'A PIECE OF CHIC',
    label: 'A PIECE OF CHIC',
    value: 'A PIECE OF CHIC',
  },
  { key: 'A-COLD-WALL', label: 'A-COLD-WALL', value: 'A-COLD-WALL' },
  { key: 'A-ESQUE', label: 'A-ESQUE', value: 'A-ESQUE' },
  { key: 'A-JANE', label: 'A-JANE', value: 'A-JANE' },
  { key: 'A-LAB MILANO', label: 'A-LAB MILANO', value: 'A-LAB MILANO' },
  { key: 'A-MORIR', label: 'A-MORIR', value: 'A-MORIR' },
  {
    key: 'A. LANGE & SOHNE',
    label: 'A. LANGE & SOHNE',
    value: 'A. LANGE & SOHNE',
  },
  { key: 'A. TESTONI', label: 'A. TESTONI', value: 'A. TESTONI' },
  { key: 'A.A SPECTRUM', label: 'A.A SPECTRUM', value: 'A.A SPECTRUM' },
  { key: 'A.BOCCA', label: 'A.BOCCA', value: 'A.BOCCA' },
  { key: 'A.CLOUD', label: 'A.CLOUD', value: 'A.CLOUD' },
  { key: 'A.EMERY', label: 'A.EMERY', value: 'A.EMERY' },
  { key: 'A.F.VANDEVORST', label: 'A.F.VANDEVORST', value: 'A.F.VANDEVORST' },
];

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },

  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'All listing Type',
    label: 'All listing Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_type'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'All listing Type', label: 'All listing Type' },
        { key: 'For Rent', label: 'For Rent' },
        { key: 'For Sale', label: 'For Sale' },
        { key: 'For Sale/Rent', label: 'For Rent/For Sale' },
      ],
    },
  },
  // {
  //   id: 'category',
  //   label: 'Category',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_category'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_any',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: "Dresses", value: "dresses", label: "Dresses" },
  //       { key: "Trousers/Jeans", value: "trouser_jeans", label: "Trousers/Jeans" },
  //       { key: "Shoes", value: "shoes", label: "Shoes" },
  //       { key: "Skirts", value: "skirts", label: "Skirts" },
  //       { key: "Tops", value: "tops", label: "Tops" },
  //       { key: "Suits", value: "suits", label: "Suits" },
  //       { key: "Coats", value: "coats", label: "Coats" },
  //       { key: "Jumpsuits", value: "jumpsuits", label: "Jumpsuits" },
  //       { key: "Playsuits", value: "playsuits", label: "Playsuits" },
  //       { key: "Bag", value: "bag", label: "Bag" },
  //       { key: "Accessories", value: "accessories", label: "Accessories" },
  //      ],
  //   },
  // },
  // {
  //   id: 'brand',
  //   label: 'Brand',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_brand'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_any',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: brand,
  //   },
  // },
  // {
  //   id: 'size',
  //   label: 'Size',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_size'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_any',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: dressSize,
  //   },
  // },
  // {
  //   id: 'colour',
  //   label: 'Colour',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_colour'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_any',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: colour,
  //   },
  // },
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  // {
  //   id: 'certificate',
  //   label: 'Certificate',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_certificate'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  //       { key: '200h', label: 'Registered yoga teacher 200h' },
  //       { key: '500h', label: 'Registered yoga teacher 500h' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const moreThanOneSize = [
  // {
  //   // key: 'category',
  //   // scope: 'public',
  //   // schemaType: 'enum',
  //   enumOptions: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    // ]}
  ]