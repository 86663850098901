import React from 'react'

const IconSliderPlus = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ fill: 'transparent' }}>
      <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#0286FF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 21L16.65 16.65" stroke="#0286FF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 8V14" stroke="#0286FF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 11H14" stroke="#0286FF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default IconSliderPlus
