import React, { useState, useEffect } from 'react';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import routeConfiguration, {
  ACCOUNT_SETTINGS_PAGES,
} from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { createResourceLocatorString } from '../../../util/routes';
import config from '../../../config';
import Select from 'react-select';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getAllCategories } from '../../../util/api';

const options = [
  { key: 'tops', label: 'Tops' },
  { key: 'bottoms', label: 'Bottoms' },
  { key: 'warmups', label: 'Warmups' },
  { key: 'party', label: 'Party' },
];

const customStyles = {
  control: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    color: '#303030',
    fontSize: '15px',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    height: '45px',
    paddingLeft: '0px',
    paddingRight: '0px',
    minHeight: '45px',
    margin: '0px',
    padding: '0px',
    fontWeight: '500',
    position: 'relative',
    cursor: 'pointer',
  }),
  valueContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#B2B2B2',
    height: '100%',
    padding: '0px',
    margin: '0px',
    fontWeight: '500',
    cursor: 'pointer',
  }),
  placeholder: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#303030',
    padding: '0px',
    margin: '0px',
    fontWeight: '500',
    cursor: 'pointer',
  }),
  indicatorsContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    padding: '0px',
    margin: '0px',
    cursor: 'pointer',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0px',
    margin: '0px',
    transition: 'all .2s ease',
    transform: state.isFocused.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  input: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#B2B2B2',
    height: '100%',
    padding: '0px',
    margin: '0px',
    fontWeight: '500',
    cursor: 'pointer',
  }),
  indicatorSeparator: styles => ({
    display: 'none',
    padding: '0px',
    margin: '0px',
  }),
  option: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#303030',
    padding: '15px',
    fontSize: '15px',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    cursor: 'pointer',
    backgroundColor: isDisabled
      ? null
      : isFocused
      ? '#F0EDF6'
      : isSelected
      ? '#FFF'
      : null,
  }),
  singleValue: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#303030',
    fontWeight: '500',
    cursor: 'pointer',
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    backgroundColor: '#fff',
    color: '#303030',
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    width: '100%',
    minWidth: '150px',
    padding: '0px',
  }),
  menuList: base => ({
    ...base,
    padding: '0px',
    backgroundColor: '#fff',
    fontSize: '15px',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    color: '#303030',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#303030',
  }),
};

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    bookmarks,
    lb,
    orderNotificationCount,
    currentUserHasOrders,
    transactions,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [selectedCategory, setSelectedOptions] = useState(null);
  const [isSearchToggle, setSearchToggle] = useState(false);
  const [customCategories, setCustomCategoires] = useState([]);

  useEffect(() => {
    setMounted(true);

    getAllCategories({}).then(response => {
      setCustomCategoires(response?.categories);
    });
  }, []);
  const clothingCategories = customCategories
    .map(item => item.attributes.Label)
    .filter(item => item !== 'Accessories');
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot =
    notificationCount > 0 ||
    orderNotificationCount > 0 ||
    currentUserHasOrders ? (
      <div className={css.notificationDot} />
    ) : null;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };
  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.navLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const signupLink = (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
  const homeLink = (
    <NamedLink name="LandingPage" className={css.menuLinks}>
      <span className={css.menuText}>
        <FormattedMessage id="LandingPage.homeLink" />
      </span>
    </NamedLink>
  );

  const aboutLink = (
    <a href="/about-us" className={classNames(css.menuLinks)}>
      <span className={css.menuText}>
        <FormattedMessage id="TopbarDesktop.about" />
      </span>
    </a>
  );
  const howItWorkLink = (
    <NamedLink name="CMSPage" params={{ pageId: 'how_to_rent' }} className={css.menuLinks}>
      <span className={css.menuText}>
        <FormattedMessage id="LandingPage.howItWorkLink" />
      </span>
    </NamedLink>
  );
  const faqLink = (
    <a href="/faq" className={css.menuLinks}>
      <span className={css.menuText}>FAQs</span>
    </a>
  );
  // const workWithUsLink = (
  //   <NamedLink
  //     name="ContentPage"
  //     params={{ tab: 'work-with-us' }}
  //     className={css.menuLinks}
  //   >
  //     <span className={css.menuText}>
  //       <FormattedMessage id="LandingPage.workWithUsLink" />
  //     </span>
  //   </NamedLink>
  // );
  const blogLink = (
    <a href="/blogs" className={css.menuLinks}>
      <span className={css.menuText}>
        <FormattedMessage id="Blog" />
      </span>
    </a>
  );
  const contactLink = (
    <a href=" /contact_us" className={css.menuLinks}>
      <span className={css.menuText}>
        <FormattedMessage id="Contact Us" />
      </span>
    </a>
  );
  const inviteNewLink = (
    <NamedLink name="InvitePage" className={classNames(css.menuLinks)}>
      <span className={css.menuText}>
        <FormattedMessage id="LandingPage.inviteNewLink" />
      </span>
    </NamedLink>
  );
  const moreLink = (
    <Menu className={css.hiddenMenuLink}>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <span className={css.menuText}>
          <FormattedMessage id="LandingPage.moreLink" />
          <span className={css.moreLinkIcon}>
            <svg
              width="10"
              height="6"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L13 1"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />{' '}
            </svg>
          </span>
        </span>
      </MenuLabel>
      <MenuContent className={css.moreOptionMenuContent}>
        <MenuItem key="HowItWork">
          <a
            target="_blank"
            href="https://www.byborrowhq.com/how-it-works"
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="LandingPage.howItWorkLink" />
          </a>
        </MenuItem>
        <MenuItem key="Faq">
          <a
            target="_blank"
            href="https://www.byborrowhq.com/faq"
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="LandingPage.faqLink" />
          </a>
        </MenuItem>
        {/* <MenuItem key="WorkWithUs">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ContactDetailsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="LandingPage.workWithUsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="BlogPage">
          <a
            target="_blank"
            href="https://www.byborrowhq.com/blog"
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="LandingPage.blogLink" />
          </a>
        </MenuItem>
        <MenuItem key="ContactDetailsPage">
          <a
            target="_blank"
            href="https://www.byborrowhq.com/contact-us"
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="LandingPage.contactUsLink" />
          </a>
        </MenuItem>
        <MenuItem key="InviteNewLink">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="LandingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="LandingPage.inviteNewLink" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );

  const downArrow = () => {
    return (
      <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L7 7L13 1"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.menuLink}>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="DashboardPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('DashboardPage')
            )}
            name="DashboardPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.dashboard" />
          </NamedLink>
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('InboxPage')
            )}
            name="InboxPage"
            params={{ tab: 'rentals' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.rentals" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="BookingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('BookingsPage')
            )}
            name="BookingBasePage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myRentals" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyLendingPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('BookingsPage')
            )}
            name="BookingPage"
            params={{ type: 'lend' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myLendings" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MySalePage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('BookingsPage')
            )}
            name="BookingPage"
            params={{ type: 'sale' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.mySales" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="MyPurchasePage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('BookingsPage')
            )}
            name="BookingPage"
            params={{ type: 'purchase' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myPurchases" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('ManageListingsPage')
            )}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="ManageListingsPage.tab" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('ProfileSettingsPage')
            )}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myProfileSettingsLink" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="CartPage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('CartPage')
            )}
            name="CartPage"
          >
            <FormattedMessage id="TopbarMobileMenu.cartitemLink" />
            {isAuthenticated ? (
              bookmarks && bookmarks.length > 0 ? (
                <span className={css.badge}>{bookmarks && bookmarks.length}</span>
              ) : null
            ) : lb && lb.length > 0 ? (
              <span className={css.badge}>{lb && lb.length}</span>
            ) : null}
          </NamedLink>

        </MenuItem> */}
        {/* <MenuItem key="InvitePage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('InvitePage')
            )}
            name="InvitePage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourInviteLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('AccountSettingsPage')
            )}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.myAccountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  // const signupLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="SignupPage" className={css.navLink}>
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       ariaHidden="true"
  //       focusable="false"
  //       role="presentation"
  //       class="icon icon-account"
  //       fill="none"
  //       viewBox="0 0 18 19"
  //       width="20px"
  //     >
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
  //         fill="currentColor"
  //       ></path>
  //     </svg>
  //   </NamedLink>
  // );

  const cartLink = (
    <NamedLink name="CartPage" className={css.navLink}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 24L10.32 22.1739C4.08 16.1739 0 12.1304 0 7.17391C0 3.13043 2.88 0 6.6 0C8.64 0 10.68 1.04348 12 2.73913C13.3176 1.04348 15.36 0 17.4 0C21.12 0 24 3.13043 24 7.17391C24 12.1304 19.92 16.1739 13.68 22.1739L12 24Z"
          fill="#F887DC"
        />
      </svg>
      {isAuthenticated ? (
        bookmarks && bookmarks.length > 0 ? (
          <span className={css.badge}>{bookmarks && bookmarks.length}</span>
        ) : null
      ) : lb && lb.length > 0 ? (
        <span className={css.badge}>{lb && lb.length}</span>
      ) : null}
    </NamedLink>
  );

  // const categoriesOptions = config.custom.categories;

  if (selectedCategory) {
    const searchParams = {
      pub_category: selectedCategory,
    };

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        searchParams
      )
    );
  }

  const categories = (
    <span className={css.chooseCategory}>
      <Select
        options={options}
        styles={customStyles}
        placeholder={intl.formatMessage({ id: 'TopbarDesktop.Categories' })}
        onChange={e => setSelectedOptions(e.value)}
      />
    </span>
  );
  const createListingLink = (
    <span className={css.listingButton}>
      {currentUser && currentUser.attributes.emailVerified == false ? (
        <NamedLink className={css.createListingLink} name="ContactDetailsPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
      ) : (
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.createListing" />
          </span>
        </NamedLink>
      )}
    </span>
  );

  const searchToggle = (
    <span
      className={classNames(css.navLink, css.seachIcon)}
      onClick={() => setSearchToggle(!isSearchToggle)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        xmlSpace="preserve"
        stroke="#333"
        width="18"
        height="20"
        viewBox="0 0 50 50"
      >
        <path d="M51.704,51.273L36.845,35.82c3.79-3.801,6.138-9.041,6.138-14.82c0-11.58-9.42-21-21-21s-21,9.42-21,21s9.42,21,21,21  c5.083,0,9.748-1.817,13.384-4.832l14.895,15.491c0.196,0.205,0.458,0.307,0.721,0.307c0.25,0,0.499-0.093,0.693-0.279  C52.074,52.304,52.086,51.671,51.704,51.273z M21.983,40c-10.477,0-19-8.523-19-19s8.523-19,19-19s19,8.523,19,19  S32.459,40,21.983,40z" />
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </span>
  );

  function handleSearchClothing() {
    const clothingItems = `${clothingCategories.toString()}`;
    const searchParams = {
      pub_category: clothingItems,
    };

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        searchParams
      )
    );
  }
  return (
    <nav className={classes}>
      <div className={css.topHeaderBar}>
        <div className={css.leftSec}>
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            />
          </NamedLink>
        </div>
        <div className={css.rightSec}>
          <div className={css.menuLinkWrap}>
            <span className={css.menuBarLinks}>
              {/* {homeLink} */}
              <NamedLink
                name="SearchPage"
                className={classNames(css.menuLinks)}
              >
                <span className={css.menuText}>
                  <FormattedMessage id="TopbarDesktop.newIn" />
                </span>
              </NamedLink>

              <div name="SearchPage" onClick={() => handleSearchClothing()} className={classNames(css.menuLinks)}>
                <span
                  className={css.menuText}
                >
                  clothing
                </span>
              </div>

              <Link
                to={'s?pub_category=Accessories'}
                className={classNames(css.menuLinks)}
              >
                <span className={css.menuText}>accessories</span>
              </Link>
              <NamedLink
                name="CollectionPage"
                className={classNames(css.menuLinks)}
              >
                <span className={css.menuText}>edits</span>
              </NamedLink>
              {howItWorkLink}
              <Link
                to={'/s?pub_type=For%20Sale%2FRent%2CFor%20Sale'}
                className={classNames(css.menuLinks)}
              >
                <span className={css.menuText}>pre-loved</span>
              </Link>

              {/* {workWithUsLink} */}
              {/* {aboutLink} */}
              {/* {blogLink} */}
              {/* {contactLink} */}
              {/* {faqLink} */}
              {/* {moreLink} */}
            </span>
            {/* <span className={css.searchinputBox}>{search}</span> */}
          </div>
          <div className={css.rightBarAuth}>
            {createListingLink}
            {/* {cartLink} */}
            <div>
              <NamedLink
                className={classNames(
                  css.profileSettingsLink,
                  currentPageClass('CartPage')
                )}
                name="CartPage"
              >
             <span>
             <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#f887dc"
                  strokeWidth="1px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 24L10.32 22.1739C4.08 16.1739 0 12.1304 0 7.17391C0 3.13043 2.88 0 6.6 0C8.64 0 10.68 1.04348 12 2.73913C13.3176 1.04348 15.36 0 17.4 0C21.12 0 24 3.13043 24 7.17391C24 12.1304 19.92 16.1739 13.68 22.1739L12 24Z"
                    fill="#ffffff"
                  />
                </svg>
            
                {isAuthenticated ? (
                  bookmarks && bookmarks.length > 0 ? (
                    <span className={css.badgeCount}>{bookmarks && bookmarks.length}</span>
                
                  ) : null
                ) : lb && lb.length > 0 ? (
                  // <span className={css.badge}>{lb && lb.length}</span>
                  <></>
                ) : null}
             </span>
              </NamedLink>
            </div>

            {isAuthenticatedOrJustHydrated ? null : (
              <div className={css.authLinks}>
                {signupLink}
                {loginLink}
              </div>
            )}

            {/* {inboxLink} */}
            {profileMenu}
          </div>
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
