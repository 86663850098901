import React from 'react';
import PropTypes from 'prop-types';
import css from './WorkCard.module.css';

const WorkCard = props => {
  const { className, cardCoverImage, cardHeading, cardSubHeading } = props;
  return(
    <div className={css.cardBox}>
        <div className={css.cardImage}>{cardCoverImage}</div>
        <div className={css.cardHeadingText}>{cardHeading}</div>
        <div className={css.cardsubHeadingText}>{cardSubHeading}</div>
    </div>
  );
};

export default WorkCard;
