import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonLoader = props => {
  const { viewport, isMobile, noOfSlides } = props;

  let slideWidth =
    viewport && noOfSlides ? viewport.width / noOfSlides - 18 : 276;
  const slideViewBoxWidth =
    viewport && noOfSlides && viewport.width / noOfSlides;

  if (slideWidth < 0) {
    slideWidth = 462;
  }

  if (isMobile) {
    return (
      <ContentLoader
        speed={2}
        width={slideWidth}
        height={234}
        viewBox={`0 0 ${slideViewBoxWidth} 334`}
        backgroundColor="#ad86b5"
        foregroundColor="#e1bde8"
        {...props}
      >
        <rect x="0" y="0" rx="8" ry="8" width={`${slideWidth}`} height="243" />
        <path d="M 0 257 h 115 v 11 H 0 z" />
        <path d="M 0 279 h 208 v 11 H 0 z" />
        <path d="M 0 299 h 174 v 11 H 0 z" />
        <path d="M 0 323 h 140 v 11 H 0 z" />
      </ContentLoader>
    );
  }
  return (
    <ContentLoader
      viewBox="0 0 500 360"
      height={360}
      width={500}
      {...props}
      backgroundColor="#e1bde8"
      foregroundColor="#ad86b5"
    >
      <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
      <rect x="0" y="99" rx="5" ry="5" width="400" height="200" />
    </ContentLoader>
  );
};

export default SkeletonLoader;
