import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPlus.module.css';

const IconPlus = props => {
  const { className, rootClassName, isOpen } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7L7 1L1 7"
        stroke="#000"
        strokeWidth="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

IconPlus.defaultProps = {
  className: null,
  rootClassName: null,
  isOpen: true,
};

IconPlus.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool,
};

export default IconPlus;
