import React, { useEffect } from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  FieldDateRangeInput,
  NamedLink,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';
import { addTime, getStartOf } from '../../../util/dates';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    listing,
    pickupLocation,
    unitType,
    timeSlots,
    isAvail,
    currentUser,
    bookingData,
    saleOrRent,
  } = formRenderProps;


  const { publicData, description, price } = listing.attributes || {};
  const { sizes, stock_size, size } = publicData || {};
  useEffect(() => {
    const { quantity: quantityRaw = 1, deliveryMethod, selectSize } = values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser && quantity && deliveryMethod && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: { quantity, deliveryMethod, selectSize, saleOrRent },
        listingId,
        isOwnListing,
      });
    }
  }, []);
  const handleOnChange = formValues => {
    const {
      quantity: quantityRaw,
      deliveryMethod,
      selectSize,
    } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && quantity && deliveryMethod && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: { quantity, deliveryMethod, selectSize, saleOrRent },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const { quantity, deliveryMethod, selectSize } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (!deliveryMethod) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else {
      handleSubmit(e);
    }
  };

  const breakdownData = {};
  const showBreakdown =
    breakdownData &&
    lineItems &&
    !fetchLineItemsInProgress &&
    !fetchLineItemsError;

  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemSaleUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
        selectSize={values.selectSize}
      />
    </div>
  ) : null;
  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({
    id: 'ProductOrderForm.quantityRequired',
  });

  const hasStock = currentStock && currentStock > 0;
  const quantities = hasStock
    ? [...Array(currentStock).keys()].map(i => i + 1)
    : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = true; // typeof currentStock != null && currentStock === 1;
  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;
  const bookingStartLabel = intl.formatMessage({
    id: 'BookingDatesForm.bookingStartTitle',
  });
  const bookingEndLabel = intl.formatMessage({
    id: 'BookingDatesForm.bookingEndTitle',
  });
  const requiredMessage = intl.formatMessage({
    id: 'BookingDatesForm.requiredDate',
  });
  const startDateErrorMessage = intl.formatMessage({
    id: 'FieldDateRangeInput.invalidStartDate',
  });
  const endDateErrorMessage = intl.formatMessage({
    id: 'FieldDateRangeInput.invalidEndDate',
  });
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };
  const isBookingData = bookingData?.filter((e) => e.relationships.listing.data.id.uuid == listingId.uuid)
  const selctedSizeStock = sizes.filter((e) => e.size_acc_to_stock == values.selectSize)
  const selctedbookingData = isBookingData?.filter((e) => e.attributes?.protectedData?.selectSize == values.selectSize)
  const isStockOne = selctedSizeStock.filter((e) => e.stock == 1 || e.stock == selctedbookingData?.length)


  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      {
        isBookingData && isBookingData.length > 0 ?
          null :
          <>
            {stock_size == 'yes' ? (
              <div className={css.productSize}>
                <div className={css.productLabel}>
                  <FormattedMessage id="BookingDatesForm.sizes" />
                </div>
                <FieldSelect id="selectSize" name="selectSize" required>
                  <option value="">Select sizes</option>
                  {sizes.map(e =>
                    parseInt(e.stock) > 0 ? (
                      <>
                        <option key={e.size_acc_to_stock} value={e.size_acc_to_stock}>
                          {e.size_acc_to_stock}
                        </option>
                      </>
                    ) : null
                  )}
                </FieldSelect>
              </div>
            ) : (
              <div className={css.productSize}>
                <div className={css.productLabel}>
                  <FormattedMessage id="BookingDatesForm.sizes" />
                </div>
                <FieldSelect id="selectSize" name="selectSize" required>
                  <option value="">Select sizes</option>
                  {size &&
                    size.map(e => {
                      return (
                        <option key={e.label} value={e.label}>
                          {e.label}
                        </option>
                      );
                    })}
                </FieldSelect>
              </div>
            )}
          </>
      }

      {hasNoStockLeft ? null : hasOneItemLeft ? (
        <FieldTextInput
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          type="hidden"
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        />
      ) : (
        <>
          <FieldSelect
            id={`${formId}.quantity`}
            className={css.quantityField}
            name="quantity"
            disabled={!hasStock}
            label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
            validate={numberAtLeast(quantityRequiredMsg, 1)}
          >
            <option disabled value="">
              {intl.formatMessage({
                id: 'ProductOrderForm.selectQuantityOption',
              })}
            </option>
            {quantities.map(quantity => (
              <option key={quantity} value={quantity}>
                {intl.formatMessage(
                  { id: 'ProductOrderForm.quantityOption' },
                  { quantity }
                )}
              </option>
            ))}
          </FieldSelect>
        </>
      )}

      {
        isBookingData && isBookingData.length > 0 ?
          null :
          <>
            {hasNoStockLeft ? null : hasMultipleDeliveryMethods ? (
              <>
                <FieldSelect
                  id={`${formId}.deliveryMethod`}
                  className={css.deliveryField}
                  name="deliveryMethod"
                  disabled={!hasStock}
                  label={intl.formatMessage({
                    id: 'ProductOrderForm.deliveryMethodLabel',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'ProductOrderForm.deliveryMethodRequired',
                    })
                  )}
                >
                  <option disabled value="">
                    {intl.formatMessage({
                      id: 'ProductOrderForm.selectDeliveryMethodOption',
                    })}
                  </option>
                  <option value={'pickup'}>
                    {intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
                  </option>
                  <option value={'shipping'}>
                    {intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })}
                  </option>
                </FieldSelect>
              </>
            ) : (
              <div className={css.deliveryField}>
                <label>
                  <b>
                    {intl.formatMessage({
                      id: 'ProductOrderForm.deliveryMethodLabel',
                    })}
                  </b>
                </label>
                <p className={css.singleDeliveryMethodSelected}>
                  {values.deliveryMethod === 'shipping'
                    ? intl.formatMessage({ id: 'ProductOrderForm.shippingOption' })
                    : intl.formatMessage({ id: 'ProductOrderForm.pickupOption' })}
                </p>
                <FieldTextInput
                  id={`${formId}.deliveryMethod`}
                  className={css.deliveryField}
                  name="deliveryMethod"
                  type="hidden"
                />
              </div>
            )}
          </>

      }
      {
        isBookingData && isBookingData.length > 0 ?
          null :
          <>
            {values.deliveryMethod && values.deliveryMethod.includes('pickup') && (
              <span> {pickupLocation && pickupLocation}</span>
            )}
          </>
      }

      {isBookingData && isBookingData.length > 0 ? null : breakdown}
      <p className={css.finePrint}>
        {hasStock ? (
          <FormattedMessage id="ProductOrderForm.finePrint" />
        ) : showContactUser ? (
          <FormattedMessage
            id="ProductOrderForm.finePrintNoStock"
            values={{ contactSellerLink }}
          />
        ) : null}
      </p>
      {currentUser && currentUser.attributes.emailVerified == false ? (
        <div className={css.verifyEmailAddress}>
          <p>
            Please verify your email first{' '}
            <NamedLink name="ContactDetailsPage">Click here!</NamedLink> .
          </p>
        </div>
      ) : null}
      {isBookingData && isBookingData.length > 0
        ? <>*Note to customers:
          The items have been booked for future rentals. Reach out to the owner and let them know that you are
          interested in buying and they can let you know once the item is available.</> : null
      }
      <div className={css.submitButton}>
        {isBookingData && isBookingData.length > 0 ?
          <PrimaryButton type="button" onClick={() => {
            onContactUser();

          }}>Contact for enquiry</PrimaryButton>
          :
          <PrimaryButton
            type="submit"
            inProgress={submitInProgress}
            disabled={
              submitDisabled ||
              isOwnListing ||
              (currentUser && currentUser.attributes.emailVerified == false) || (isBookingData && isBookingData.length && isStockOne && isStockOne.length && selctedbookingData && selctedbookingData.length)
            }
          >
            {hasStock ? (
              <FormattedMessage id="ProductOrderForm.ctaButton" />
            ) : (
              <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
            )}
          </PrimaryButton>
        }
      </div>
    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const { price, currentStock, pickupEnabled, shippingEnabled, bookingData } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : { quantity: '1' };
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
        ? { deliveryMethod: 'pickup' }
        : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = {
    ...quantityMaybe,
    ...singleDeliveryMethodAvailableMaybe,
  };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
  timeSlots: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,
  unitType: propTypes.lineItemUnitType.isRequired,
  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,
  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderForm;
