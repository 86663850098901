import React, { Component, useState } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage, Button, Avatar } from '..';

import css from './CartCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getAuthorName = (cl) => {
  const authorName = cl &&  cl.author && cl.author.attributes && cl.author.attributes.profile && cl.author.attributes.profile.displayName;
  return authorName
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const CartCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    bookmarks,
    handleWishlist,
    isAuthenticated,
    handleAdd,
    handleMinus,
    stockCount = "1",
    stockDetails,
    handleRemoveFromState = () => { }
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const index = stockDetails && stockDetails.findIndex(item => item.listingId == id);
  const stock = stockDetails && stockDetails[index];

  const { title = '', price, publicData } = currentListing.attributes;
  const currentStock = currentListing && currentListing.currentStock;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  let firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  const [isHovered, setHovered] = useState('');
  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  if (isHovered && currentListing && currentListing.images && currentListing.images.length > 1) {
    firstImage = currentListing.images[1];
  };

  const itemTotal = price && stock && stock.stockCount > 1 ? (price.amount / 100) * stock?.stockCount : (price.amount / 100) * 1;
  const availableQuantity = currentStock && currentStock.attributes && currentStock.attributes.quantity;
  const toUpperCase = (str) => { return (str[0].toUpperCase() + str.substring(1)) };
  return (
    <div className={classes}>
      <div className={css.productDetails}>
        <div className={css.productDetailsLeft}>
          <div className={css.productImg}>
            <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
              <AspectRatioWrapper
                className={css.aspectRatioWrapper}
                width={aspectWidth}
                height={aspectHeight}
                {...setActivePropsMaybe}
                onMouseOver={() => setHovered('hover')}
                onMouseOut={() => setHovered('')}
              >
                <LazyImage
                  rootClassName={css.rootForImage}
                  alt={title}
                  image={firstImage}
                  variants={variants}
                  sizes={renderSizes}
                />
              </AspectRatioWrapper>
            </NamedLink>
          </div>
          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
                  {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </NamedLink>
              </div>
              <div className={css.authorInfo}>
                {publicData && publicData.brand && toUpperCase(publicData && publicData.brand)}
              </div>
              {/* <div className={css.authorInfo}>
              Size: {publicData && publicData.size}
              </div> */}
            </div>
            <div className={classNames(css.totalPrice, css.author)}>

              <div className={css.authorInfo}>
                <Avatar user={listing?.author} className={css.avatar} />
                {getAuthorName(listing)}
              </div>
              <div className={css.favorite}>
                {bookmarks && bookmarks.findIndex(e => e == id) > -1 ? (
                  <div
                    className={css.bookmark}
                    onClick={e => {
                      handleRemoveFromState(id)
                      handleWishlist(id, e);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 24L10.32 22.1739C4.08 16.1739 0 12.1304 0 7.17391C0 3.13043 2.88 0 6.6 0C8.64 0 10.68 1.04348 12 2.73913C13.3176 1.04348 15.36 0 17.4 0C21.12 0 24 3.13043 24 7.17391C24 12.1304 19.92 16.1739 13.68 22.1739L12 24Z"
                        fill="#F887DC"
                      />
                    </svg>
                    {/* <span>Remove</span> */}
                  </div>
                ) : null}
              </div>
            </div>

          </div>
        </div>
        {/* <div className={css.productDetailsRight}>
          <div className={css.stock}>
            <Button type="button" onClick={handleMinus} disabled={availableQuantity <= 1}>
              -
            </Button>
            <span className={css.stockCount}>{stock && stock.stockCount || "1"}</span>
            <Button type="button" onClick={handleAdd} disabled={availableQuantity == stock?.stockCount}>
              +
            </Button>
          </div>
        </div> */}
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
          €{itemTotal}
          </div>
          {config.listing.showUnitTypeTranslations ? (
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CartCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

CartCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(CartCardComponent);
