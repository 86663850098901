import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import config from '../../config';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentTransactionView } from '../../util/api';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { TRANSITIONS } from '../../util/transaction';
import { TRANSITIONS as TRANSITIONSRENT } from '../../util/transactionRent';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// Helper to fetch correct image variants for different thunk calls
const getImageVariants = () => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/MyRentals/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/MyRentals/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/MyRentals/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

export const viewCurrentTransaction = (params) => (dispatch, getState, sdk) => {

  currentTransactionView({ params }).then((e) => {

  })
}

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());
  const { type = 'rent' } = params;
  const { page = 1 } = parse(search);
  // const allTransactions = [...TRANSITIONS, ...TRANSITIONSRENT]
  // console.log(TRANSITIONS, TRANSITIONSRENT, '^^^^ ^^^^ => TRANSITIONS, TRANSITIONSRENT');
  
  const allTransactions = [
      'transition/decline',
      'transition/auto-complete',
      'transition/review-1-by-provider',
      'transition/review-2-by-provider',
      'transition/review-1-by-customer',
      'transition/review-2-by-customer',
      'transition/expire-review-period',
      'transition/expire-provider-review-period',
      'transition/expire-customer-review-period',
    ];

  const apiQueryParams = {
    only: ['rent', 'purchase'].includes(type) ? 'order' : 'sale',
    lastTransitions: allTransactions,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.listing': ['title', 'price', "publicData"],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,

    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(async response => {
      dispatch(addMarketplaceEntities(response));
      const listings = [];
      response.data.included
        .filter(listing => listing.type == "listing")
        .map(listing => listings.push(sdk.listings.show({
          id: listing.id,
          include: ['author', 'author.profileImage', 'images'],
          ...getImageVariants(),
        })));
      const listingData = await Promise.all(listings);
      if (listingData && listingData.length) {
        listingData.map(listing => dispatch(addMarketplaceEntities(listing)));
      }
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
