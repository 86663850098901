import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.module.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="5" fill="#03A9F4"/>
    <path d="M9.026 18.0007C15.064 18.0007 18.367 12.9977 18.367 8.66669C18.367 8.52669 18.367 8.38469 18.361 8.24469C19.0041 7.77918 19.5591 7.20284 20 6.54269C19.3993 6.80754 18.7628 6.9821 18.111 7.06069C18.7975 6.65033 19.3117 6.00466 19.558 5.24369C18.913 5.62582 18.207 5.89407 17.471 6.03669C16.9762 5.50972 16.3214 5.16061 15.6081 5.04345C14.8948 4.92629 14.1627 5.04761 13.5253 5.38863C12.888 5.72965 12.3808 6.27132 12.0824 6.92975C11.7841 7.58818 11.7112 8.32663 11.875 9.03069C10.5697 8.96524 9.29282 8.62616 8.12704 8.03544C6.96127 7.44471 5.93268 6.61554 5.108 5.60169C4.68934 6.32478 4.56157 7.18009 4.75065 7.99396C4.93972 8.80782 5.43145 9.51922 6.126 9.98369C5.60554 9.96601 5.09652 9.82625 4.64 9.57569V9.62069C4.6409 10.3782 4.90331 11.1121 5.38287 11.6985C5.86244 12.2848 6.52975 12.6876 7.272 12.8387C6.99026 12.9163 6.69923 12.955 6.407 12.9537C6.20098 12.9543 5.99538 12.9352 5.793 12.8967C6.00279 13.5487 6.41127 14.1189 6.96125 14.5272C7.51122 14.9355 8.17513 15.1615 8.86 15.1737C7.69656 16.0875 6.2594 16.5831 4.78 16.5807C4.51932 16.5818 4.25882 16.5668 4 16.5357C5.50151 17.493 7.2453 18.0012 9.026 18.0007Z" fill="white"/>
    </svg>
  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
