import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { FieldTextInput, PrimaryButton, Form } from '../../components';
import css from './SubscribeForm.module.css';


const SubscribeFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { formId, handleSubmit, onChange, intl, values } = fieldRenderProps;

      const emailPlaceholder = intl.formatMessage({
        id: 'InviteForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(
        messageRequiredMessage
      );
      return (
        <Form
          onSubmit={e => {
            handleSubmit(e);
            onChange(e);
          }}
          enforcePagePreloadFor="OrderDetailsPage"
        >
          <div
            className={css.rowWrapper}
          >
            <FieldTextInput
              // className={css.labelText}
              type="text"
              id={formId ? `${formId}.firstName` : 'firstName'}
              name="firstName"
              autoComplete="firstName"
              label={'FirstName *'}
            // placeholder={emailPlaceholder}
            // validate={validators.composeValidators(emailRequired, emailValid)}
            />

            <FieldTextInput
              type="email"
              // className={css.labelText}
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={'Email *'}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
          </div>
          <PrimaryButton
            type="submit"
            disabled={!values.firstName || !values.email}
          >
            Send
          </PrimaryButton>
        </Form>
      );
    }}
  />

);

SubscribeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

SubscribeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  inProgress: bool,
  sendEnquiryError: propTypes.error,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SubscribeForm = compose(injectIntl)(SubscribeFormComponent);

SubscribeForm.displayName = 'SubscribeForm';

export default SubscribeForm;
