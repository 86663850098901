import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPurchase.module.css';

const IconPurchase = props => {
  const { className, rootClassName, isOpen } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.5 24.25C47.5 11.4094 37.0906 1 24.25 1C11.4094 1 1 11.4094 1 24.25C1 37.0906 11.4094 47.5 24.25 47.5C37.0906 47.5 47.5 37.0906 47.5 24.25Z"
        fill="white"
        stroke="#000"
        strokeWidth="1.5"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9686 33.9495C19.4786 33.9495 19.893 34.364 19.893 34.8739C19.893 35.3838 19.4786 35.7971 18.9686 35.7971C18.4587 35.7971 18.0455 35.3838 18.0455 34.8739C18.0455 34.364 18.4587 33.9495 18.9686 33.9495Z"
        stroke="#000"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5653 33.9495C33.0752 33.9495 33.4896 34.364 33.4896 34.8739C33.4896 35.3838 33.0752 35.7971 32.5653 35.7971C32.0553 35.7971 31.6409 35.3838 31.6409 34.8739C31.6409 34.364 32.0553 33.9495 32.5653 33.9495Z"
        stroke="#000"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3228 13.9271L15.8361 14.3621L16.9997 28.2253C17.094 29.3551 18.0377 30.2227 19.1711 30.2227H32.3565C33.4391 30.2227 34.3575 29.4276 34.5133 28.3546L35.66 20.4303C35.8014 19.4528 35.0438 18.578 34.0566 18.578H16.2397"
        stroke="#000"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M27.0682 23.0438H30.4189"
        stroke="#000"
        strokeWidth="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

IconPurchase.defaultProps = {
  className: null,
  rootClassName: null,
  isOpen: true,
};

IconPurchase.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool,
};

export default IconPurchase;
