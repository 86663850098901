import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemShippingFeeMaybe = props => {
  const { lineItems, intl } = props;

  const shippingFeeLineItem = lineItems.filter(
    item => item.code === LINE_ITEM_SHIPPING_FEE 
  );

  const shippingPrices = shippingFeeLineItem && shippingFeeLineItem.map(item => item.lineTotal);
  const sum = shippingPrices.reduce(function (acc, obj) { return acc + obj.amount; }, 0);
  const totalShipping = sum && new Money(sum, 'EUR') && formatMoney(intl, new Money(sum, 'EUR'));

  return shippingFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.shippingFee" />
      </span>
      <span className={css.itemValue}>{totalShipping}</span>
    </div>
  ) : null;
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
