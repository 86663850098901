import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  // IconSocialMediaTwitter,
  IconSocialMediaPinterest,
  IconSocialMediaTikTok,
  Logo,
  ExternalLink,
  NamedLink,
  IconSocialMediaYouTube,
} from '../../components';

import css from './Footer.module.css';
import footerLogo from '../../assets/output-onlinepngtools.png';
import IconLocation from '../IconLocation/IconLocation';
import { newsLetter } from '../../util/api';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });
  const goToTikTok = intl.formatMessage({ id: 'Footer.goToTikTok' });
  const goToYouTube = intl.formatMessage({ id: 'Footer.goToYouTube' });
  

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={goToFb}
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagramDesktop"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteInstagramPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <IconSocialMediaTwitter />
  //   </ExternalLink>
  // ) : null;

  const pinterestLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteTwitterPage}
      className={css.icon}
      title={goToPinterest}
    >
      <IconSocialMediaPinterest />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToTiktok"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTikTok}
    >
      <IconSocialMediaTikTok />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteInstagramPage ? (
    <ExternalLink
      key="youtubeLink"
      href={siteTwitterPage}
      className={css.icon}
      title={goToYouTube}
    >
      <IconSocialMediaYouTube />
    </ExternalLink>
  ) : null;

  return [fbLink, instragramLink, pinterestLink, tiktokLink, youtubeLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const [email, setEmail] = useState('');  
  const [message, setMessage] = useState(''); 

  const handleInputChange = e => {
    setEmail(e.target.value);
    setMessage(''); 
  };

  // Handle email submission
  const handleEmailSubmit = async () => {
    if (!email.trim()) {
      setMessage('Please enter a valid email.');
      return;
    } 
    const data = {
      data: { email: email.trim() },
    }; 
    try { 
      const response = await newsLetter(data);
      if (response.state) {
        setMessage('Thank you for subscribing!');
        setEmail('');
      } else {
        const error = await response.json();
        setMessage(error.error || 'Subscription failed. Please try again.');
      }
    } catch (err) {
      console.error('Error:', err);
      setMessage('An error occurred. Please try again later.');
    }
  };
  
  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              {/* <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <img src={footerLogo} alt="" />
                </span>
              </NamedLink> */}

              <h4 className={css.siteTitle}>
                <FormattedMessage id="Footer.brandName" />
              </h4>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href="/about-us" className={css.link}>
                    <FormattedMessage id="Footer.AboutUs" />
                  </a>
                </li>

                {/* <li className={css.listItem}>
                  <NamedLink
                    name="InvitePage"
                    className={css.link}
                    params={{ tab: 'invite' }}
                  >
                    <FormattedMessage id="LandingPage.inviteNewLink" />
                  </NamedLink>
                </li> */}

                <li className={css.listItem}>
                  <a href="/faq" className={css.link}>
                    <FormattedMessage id="Footer.FAQS" />
                  </a>
                </li>
                <li className={css.listItem}>
                  <a href="/contact_us" className={css.link}>
                    <FormattedMessage id="Footer.CONTACTUS" />
                  </a>
                </li>
                {/* <li className={css.listItem}>
                  <a
                    href=" /contact_us"
                    className={css.link}
                  >
                    <span className={css.menuText}>
                      <FormattedMessage id="ContactUs" />
                    </span>
                  </a>
                </li> */}
                {/* <li className={css.listItem}>
                  <a
                    href=" /privacy-policy"
                    className={css.link}
                  >
                    <span className={css.menuText}>
                      <FormattedMessage id="PrivacyPolicy" />
                    </span>
                  </a>
                </li> */}
                {/* <li className={css.listItem}>
                  <a
                    href=" /news-letter"
                    className={css.link}
                  >
                    <span className={css.menuText}>
                      <FormattedMessage id="Newsletter" />
                    </span>
                  </a>
                </li> */}
                {/* <li className={css.listItem}>
                  <a
                    href=" /blogs"
                    className={css.link}
                  >
                    <span className={css.menuText}>
                      <FormattedMessage id="Blog" />
                    </span>
                  </a>
                </li> */}
                {/* <div className={css.conatctEmail}>hello@byborrow.com</div> */}
              </ul>

              {/* <div className={css.icon}>
                <a href='https://goo.gl/maps/3GMcofJBU9b45a71A' target='_blank' ><IconLocation /></a>
              </div> */}
              {/* <p className={css.address}>
                <FormattedMessage id="Footer.address1" />
                <br />
                <FormattedMessage id="Footer.address2" />
                <br />
                <FormattedMessage id="Footer.address3" />
                <br />
                <FormattedMessage id="Footer.address4" />
                <br />
                <FormattedMessage id="Footer.address5" />
              </p> */}
            </div>

            <div className={css.searchesExtra}>
              <div className={css.list}>
                {/* <h2 className={css.footerHeading}>Information</h2> */}
                <ul>
                  <li className={css.listItem}>
                    {/* <a
                      href=" /how-it-works"
                      className={css.link}
                    >
                      <span className={css.menuText}>
                        <FormattedMessage id="Footer.HowItWorks" />
                      </span>
                    </a> */}
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: 'how_to_rent' }}
                      className={css.link}
                    >
                      <span className={css.menuText}>
                        <FormattedMessage id="LandingPage.howItWorkLink" />
                      </span>
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <a href=" /terms-of-service" className={css.link}>
                      <span className={css.menuText}>
                        <FormattedMessage id="Footer.T&C" />
                      </span>
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <a href=" /privacy-policy" className={css.link}>
                      <span className={css.menuText}>
                        <FormattedMessage id="Footer.SUSTAINABILITY" />
                      </span>
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <a href=" /privacy-policy" className={css.link}>
                      <span className={css.menuText}>
                        <FormattedMessage id="Footer.PRESS" />
                      </span>
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <a href=" /privacy-policy" className={css.link}>
                      <span className={css.menuText}>
                        <FormattedMessage id="Footer.REFUNDPOLICY" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={classNames(css.organization, css.organization2)}
              id="organization"
            >
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <img src={footerLogo} alt="" />
                </span>
              </NamedLink>
            </div>
            <div className={css.extraLinks}>
              <div className={css.socialMediaHeading}>
                <span>
                  <FormattedMessage id="Footer.JOINTHECLUB" />
                </span>
                <input
                  className={css.subscribe}
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={handleInputChange}
                />
                <button
                  className={css.subsBtn}
                  type="button"
                  onClick={handleEmailSubmit}
                >
                  &#10148;
                </button>
              </div>
              <div className={css.emailContainer}>
                <div className={css.socialMediaSubtitle}>
                  <FormattedMessage id="Footer.commitment" />
                </div>
              </div>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              {/* <div className={css.someLinks}></div> */}
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink
              name="LandingPage"
              className={css.organizationCopyrightMobile}
            >
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <a
                target="_blank"
                href="https://www.byborrow.com/en/infos/terms"
                className={css.terms}
              >
                <FormattedMessage id="Footer.terms" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={css.copyright}>
        <p>© 2022 byBorrow,All Right Reserved.</p>
      </div> */}
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
