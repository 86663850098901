import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import moment from "moment";
import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, unitType, intl, selectSize, transaction, publicData, } = props;
  const { title } = transaction && transaction.listing?.attributes || {}

  const { stock_size } = publicData || {}
  const { protectedData } = transaction.attributes || {};
  const { selectSize: size } = protectedData || {};
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === unitType && !item.reversal);

  // const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const orderedSize = size ? size : selectSize ? selectSize : null;

  const translationKey = orderedSize ? isNightly
    ? 'OrderBreakdown.baseUnitNightQTY'
    
    : isDaily
      ? 'OrderBreakdown.baseUnitDayQTY'
      : 'OrderBreakdown.baseUnitQuantityQTY' : isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
      ? 'OrderBreakdown.baseUnitDays'
      : 'OrderBreakdown.baseUnitQuantity';

  const { start = null, end = null } = transaction && transaction.booking ? transaction.booking.attributes : { start: null, end: null };
  const quantity = start && end ? moment(end).diff(start, 'days') : 1; // taking quantity from days as we have custom pricing for 3, 7 & 14 days

  return quantity && total ? (
    <>
      {orderedSize ?
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id={translationKey} values={{ unitPrice,quantity, orderedSize }} />
          </span>
          <span className={css.itemValue}>{total}</span>
        </div> :
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
          </span>
          <span className={css.itemValue}>{total}</span>
        </div>
      }

    </>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  unitType: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
