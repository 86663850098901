import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
      Greenforgreensyou is a peer to peer platform for secondhand fashion rental & resale. In this notice and any linked information, references to ‘us’, ‘our’ or ‘we’ are to ByBorrow. If you have any questions about the use of your personal data, please contact us at hello@greensaregoodforyou.com.
      </p>

      <h2>Collection of your personal data</h2>
      <p>
      We will collect, store and use the personal data provided by you so that we can manage our relationship with our users. We may also collect personal data from other sources for purposes such as linking any social media accounts to your user’s profile. We will also generate information about you ourselves.<br/><br/>
      This notice is to inform you about what information we process about you and the ways in which we intend to use it. This notice applies to information we use throughout your membership with byBorrow, including any future changes to details we currently hold. To ensure we keep your information up to date and accurate it is important that you notify us of any changes to your personal details as soon as they are known.
      </p>

      <h2>Types of personal data we collect may include:</h2>
      <ul className={css.policyBlock}>
        <li>your name, contact details, including email, postal address and telephone number, date of birth and gender;</li>
        <li>a detail of all your transactions on the platform;</li>
        <li>a record of your messages through the user’s message centre;</li>
        <li>a record of any messages through our resolution centre;</li>
        <li>photographs of you from your user’s profile or participation in our events;</li>
        <li>contact details for business continuity.</li>
      </ul>
      <p>We may collect this information in a variety of ways through signing up via your existing social media or an online form you complete on our website.</p>


      <h2>Use of your personal data</h2>
      <p>
      The lawful basis for collecting and using the personal data will depend on the specific reason we have collected it for. We will collect personal data from you for the following reasons:
      </p>
      <ul className={css.policyBlock}>
        <li>where we have your consent to do so,</li>
        <li>where we need the personal data in relation to a transaction via our platform you entered into,</li>
        <li>where necessary to fulfil a legal obligation to collect the personal data from you,</li>
        <li>where the processing is necessary for our legitimate interests and is fair when balanced against your interests and rights.</li>
      </ul>

      <p>Greenforgreensyou only holds personal data relating to our users in the course of delivering byBorrow’s formal business activities.</p>
      <p>Greenforgreensyou only collects the data required for authorised membership purposes or as required for the participation of users in our activities and events. </p>
      <p>We also collect some data in seeking feedback from participants and users or through the participation of others in social events we organise. Storage and retention of your personal data. </p>
      <p>Greenforgreensyou will retain your personal data for as long as is necessary for the purpose it was collected, i.e. for the duration of your time working with us, or longer if it is needed, for resolution purposes as an example. We will hold your personal data in compliance with our usual records disposal practice procedures.</p>
      <p>Disclosure and sharing of your personal data. As part of membership with us we will need to use and share your personal data internally. We will only disclose your personal data to third parties outside byBorrow where we have a lawful basis or legal obligation for doing so. This will include third parties which provide services in support of our work such as IT processors. </p>
      <p>Greenforgreensyou will not share your information for any commercial marketing purposes. </p>
      <p>Greenforgreensyou recognises the importance of protecting personal and confidential information and we take due care to meet our legal duties and obligations. byBorrow has sought to ensure that its small number of external data processors (IT support, financial & insurance institutions and service providers) have in place all reasonable technical, security and procedural controls required to protect personal data for as long as we are required to hold that information.</p>
      <h2>Your rights</h2>
      <p>We will ensure you can exercise your rights in relation to the personal data you provide to us, as follows:</p>
      <ul className={css.policyBlock}>
        <li>Where we are relying on your consent to use your personal data, you can withdraw that consent at any time by writing to us.</li>
        <li>You can request access to the personal data we hold about you by contacting us directly.</li>
        <li>You can ask us to update your personal data if it changes. In certain circumstances, you can request we erase the personal data we hold, or ask us to stop or restrict processing if you have objections.</li>
        <li>If you have any concerns relating to the use of your personal data, you may complain to us directly.</li>
      </ul>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
