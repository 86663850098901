import React from 'react';
// import { TRANSITION_CANCEL_BY_CUSTOMER_AFTER_ACCEPT, TRANSITION_CANCEL_BY_CUSTOMER_BEFORE_ACCEPT, TRANSITION_CANCEL_BY_PROVIDER_AFTER_ACCEPT } from '../../util/renttransaction';

import css from './CompleteListItem.module.css';
import ListingCardInbox from '../ListingCardInbox/ListingCardInbox';
import classNames from 'classnames';


const CompleteListItem = props => {
  const { transactions, params, Booking, currentUser,cardContainerClassName } = props;

  const cardContainerClasses = classNames(css.imageContent ,cardContainerClassName);

  return (
    <div className={css.sectionContainer}>
      <div className={css.container}>
        <div className={css.titleWrapper}>
        </div>
        <div className={cardContainerClasses}>
          {transactions &&
            transactions?.length > 0 &&
            transactions?.filter((e) => 
              e.attributes.lastTransition == "transition/decline" ||
              e.attributes.lastTransition == "transition/review-2-by-provider" ||
              e.attributes.lastTransition == "transition/review-1-by-provider" ||
              e.attributes.lastTransition == "transition/review-2-by-customer" ||
              e.attributes.lastTransition == "transition/review-1-by-customer" 
             ).map((tx, i) => (
                <div className={css.productContent}>
                  <ListingCardInbox
                    key={(tx.id.uuid) + i}
                    transaction={tx}
                    params={params}
                    Booking={Booking}
                    currentUser={currentUser}
                  />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default CompleteListItem;
