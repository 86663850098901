import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';
import IconSale from '../../containers/SearchPage/IconSale/IconSale';
import IconPurchase from '../../containers/SearchPage/IconPurchase/IconPurchase';

const Tab = props => {
  const {
    className,
    id,
    pageName,
    disabled,
    text,
    selected,
    linkProps,
    icon,
    completed,
  } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
    [css.completed] :completed
  });


  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {pageName == 'EditListingPage' ? (
          completed ? (
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.75"
                y="0.75"
                width="46.5"
                height="46.5"
                rx="23.25"
                fill="white"
                stroke="#000"
                strokeWidth="1.5"
              />
              <path
                d="M33.0695 14.2527C29.0331 16.4696 25.6479 19.9292 22.7835 23.5567C21.8069 24.7994 20.6026 26.2101 19.5936 27.7218C18.4218 25.9416 16.9895 24.3294 15.3295 22.9522C14.5157 22.2805 13.3764 22.146 12.5626 22.9522C11.8789 23.6577 11.7488 25.1353 12.5626 25.8073C14.6783 27.554 16.371 29.6699 17.608 32.1555C17.6405 32.1891 17.6405 32.2228 17.6732 32.2563C17.8684 34.2044 20.9282 34.809 21.514 32.5585C21.514 32.4913 21.5465 32.4578 21.5465 32.4243V32.3908C21.6441 32.2228 21.7093 32.0213 21.8069 31.8533C22.0998 31.2823 22.4254 30.7449 22.7835 30.2074C23.6949 28.7632 24.7039 27.3523 25.7781 26.0424C28.3823 22.7843 31.3769 19.6942 34.99 17.7125C37.2684 16.503 35.2829 13.0434 33.0695 14.2526L33.0695 14.2527Z"
                fill="#000"
              />
            </svg>
          ) : (
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.75"
                y="0.75"
                width="46.5"
                height="46.5"
                rx="23.25"
                fill="white"
                stroke="#F887DC"
                strokeWidth="1.5"
              />
            </svg>
          )
        ) : null}
        {icon}
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return <Tab key={id} id={id} className={tabClasses} {...tab} />;
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
