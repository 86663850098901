
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { types as sdkTypes ,createImageVariantConfig} from '../../util/sdkLoader';
const { UUID } = sdkTypes;

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';
export const FETCH_TOTALCOLLECTION_SUCCESS = 'app/CollectionPage/FETCH_TOTALCOLLECTION_SUCCESS';
export const FETCH_LISTINGCOLLECTION_SUCCESS = 'app/CollectionPage/FETCH_LISTINGCOLLECTION_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/CollectionPage/SHOW_LISTING_ERROR';
export const SHOW_LISTINGS_SUCCESS = 'app/CollectionPage/SHOW_LISTINGS_SUCCESS';
export const  FETCH_COLLECTIONSHOW_SUCCESS = 'app/CollectionPage/FETCH_COLLECTIONSHOW_SUCCESS';
const resultIds = data => data.data.map(l => l.id);
const successAction = actionType => result => ({ type: actionType, payload: result.data });
// ================ Reducer ================ //
const initialState = {
  currentUser: null,
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  userListingRefs: [],
  currentPageResultIds: [],
  showlistresult:[],
  collectionPageResultIds: [],
  userShowError: null,
  queryListingsError: null,
  transactionRefs: [],
};

export default function CollectionPage(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case FETCH_TOTALCOLLECTION_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case FETCH_LISTINGCOLLECTION_SUCCESS:
      return {
        ...state,
        collectionPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
      case FETCH_COLLECTIONSHOW_SUCCESS:
      return {
        ...state,
        showlistresult: resultIds(payload.data),
        searchInProgress: false,
      };
    default:
      return state;
  }
}

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const ListingsSuccess = response => ({
  type: FETCH_TOTALCOLLECTION_SUCCESS,
  payload: { data: response.data },
});
export const ListingsCollection = response => ({
  type: FETCH_LISTINGCOLLECTION_SUCCESS,
  payload: { data: response.data },
});

export const Collectionshow = response => ({
  type: FETCH_COLLECTIONSHOW_SUCCESS,
  payload: { data: response.data },
});
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);

export const collectiondisplay = () => async (dispatch, getState, sdk) => {
  const data = await sdk?.listings.query()
  if (data) {
    dispatch(addMarketplaceEntities(data));
    dispatch(ListingsSuccess(data));
    return data;
  }
};

export const collectionchild = (id) => async (dispatch, getState, sdk) => {
  const data = await sdk?.listings.query()
  if (data) {
    dispatch(addMarketplaceEntities(data));
    dispatch(ListingsCollection(data));
    return data;
  }
};
const datas = [];


export const collectionproductshow = (id) => async (dispatch, getState, sdk) => {
  const { aspectWidth = 1, aspectHeight = 2, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  try {
    const params = {
      ids:id,
      include: ['author', 'images', 'author.profileImage'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', `variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    };
      const data = await sdk?.listings.query(params);
      if (data) {
        dispatch(addMarketplaceEntities(data));
        dispatch(Collectionshow(data));
        return data;
      }
  } catch (error) {
    console.error('Error fetching product:', error);
  }

};

export const loadData = params => (dispatch, getState, sdk, data) => {
  return Promise.all([dispatch(collectiondisplay()),
  dispatch(collectionchild())
  ])

    .then(response => {
    })
    .catch(e => {
      throw e;
    });
};
