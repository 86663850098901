import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { array, string, func, shape } from 'prop-types';

import config from '../../config';
import {
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE,
} from '../../util/transactionRent';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { NamedLink, ResponsiveImage } from '../../components';

import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './ListingCardInbox.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} alt={"test"} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
});

export const ListingCardInboxComponent = (props) => {
  const {
    className,
    rootClassName,
    intl,
    params,
    transaction,
    renderSizes,
    currentUser,
    toggleType
  } = props;
  const { type } = params;
  const classes = classNames(rootClassName || css.ListingcardInboxroot, className);

  const currentTransaction = ensureListing(transaction);
  const { selectedTime, selectedPrice, diffBetweenDays } = currentTransaction && currentTransaction.attributes?.metadata?.bookingData || {};
  const paymentStatus = currentTransaction?.booking?.attributes?.state;
  const isEnquiryState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_ENQUIRE;

  const id = currentTransaction.id.uuid;
  const { title = '', price, publicData } = currentTransaction?.listing?.attributes;
  const firstImage =
    currentTransaction?.listing?.images &&
      currentTransaction?.listing?.images.length > 0
      ? currentTransaction?.listing?.images[0]
      : null;

  const { priceTitle } = priceData(price, intl);
  const saleOrRent = transaction?.attributes?.protectedData?.saleOrRent;
  const userRole = currentTransaction?.customer?.id?.uuid === currentUser?.id?.uuid;
  const bookingStartDate = moment(currentTransaction?.booking?.attributes.start).format("DD MMM YYYY")
  const bookingendDate = moment(currentTransaction?.booking?.attributes.end).format("DD MMM YYYY")

  // view detail
  // const transitions = currentTransaction?.attributes?.transitions;
  const isRentOrder = currentTransaction.booking;

  return (
    <div className={classes}>
      <div className={css.threeToTwoWrapperInbox}>
        <div className={css.aspectWrapperInbox}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['listing-card', 'listing-card-2x']}
            sizes={renderSizes}
          />
        </div>
      </div>

      <div className={classNames(css.info, css.infoDesktop)}>
        <div className={css.mainInfo}>
          <h4 className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </h4>
        </div>
        <div className={css.price}>
          {isEnquiryState
            ? null
            : <div className={css.priceContent}>
              <p className={css.perUnit} title={priceTitle}>
                $
              </p>
              <p className={css.perUnit}>
                {price.amount / 100.00}
              </p>
            </div>}
        </div>
        {/* <div>
          {paymentStatus == 'declined' && currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_DECLINE
            ? (
              <p className={classNames(css.statusDeclined, css.status)}>
                Declined
              </p>
            )
            : null}
        </div> */}
        <p>{publicData.sizeOptions}</p>
      </div>

      <div className={css.delivaryDate}>
        <div>
          <p> <span className={css.iconBooking}>{'>'}</span>{type == 'rent' ? <FormattedMessage id="ListingCardInbox.rentalReceive" /> : type == 'lend' ? <FormattedMessage id="ListingCardInbox.lenderReceive" /> : type == 'purchase'
            ? <FormattedMessage id="ListingCardInbox.purchasedOn" /> : <FormattedMessage id="ListingCardInbox.soldOn" />} <span className={css.bookingDate}>{bookingStartDate} </span>
          </p>
          <p className={css.condition}>{type === "rent" ? <FormattedMessage id="ListingCardInbox.rentalReceiveCon" /> : type === "lend" ? <FormattedMessage id="ListingCardInbox.lenderReceiveCon" /> : null}</p>
        </div>

        <div>
          {(type === "lend" || type === "rent") ? <p><span className={css.iconBooking}>{'<'}</span> {type == 'rent' ? <FormattedMessage id="ListingCardInbox.rentalReturn" /> : type == 'lend' ? <FormattedMessage id="ListingCardInbox.lenderReturn" /> : null}  <span className={css.bookingDate}>{bookingendDate} </span>
          </p> : null}
          <p className={css.condition}>{type === "rent" ? <FormattedMessage id="ListingCardInbox.rentalReturnCon" /> : type === "lend" ? <FormattedMessage id="ListingCardInbox.lenderReturnCon" /> : null}</p>
        </div>

      </div>
      <NamedLink
        name={(params.type == 'lend' && !isRentOrder) || params.type == 'sale'
          ? 'SaleDetailsPage'
          : isRentOrder
            ? 'RentOrderDetailsPage'
            : 'OrderDetailsPage'}
        params={{ id, type: 'dashboard' }}
        // to={{isdetail:true}}
        className={css.viewDetails}
      >
        <span className={css.menuItemBorder} />
        {paymentStatus == 'declined' && currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_DECLINE
          ? (
            <> Decline</>
          )
          : <p>View Details</p>}
      </NamedLink>



      <div className={classNames(css.info, css.infoMobile)}>
        <div className={css.titleContainer}>
          <div className={css.mainInfo}>
            <h4 className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </h4>
          </div>
          <div className={css.price}>
            {
              isEnquiryState ?
                null
                :
                <div className={css.priceContent}>
                  <h5 className={css.priceValue} title={priceTitle}>
                    $ {selectedTime == "1" ? diffBetweenDays * selectedPrice : selectedPrice}
                  </h5>
                  <p className={css.perUnit}>
                    {price.amount / 100.00}
                  </p>
                  <p>{publicData.sizeOptions}</p>
                  <p>At your door by : {bookingStartDate} </p>
                  <p>In the Post by : {bookingendDate} </p>

                  {paymentStatus == 'declined' && currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_DECLINE
                    ? (
                      <p className={classNames(css.statusDeclined, css.status)}>
                        Declined
                      </p>
                    )
                    : null}

                  {/* {paymentStatus == 'declined' && currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_DECLINE
            ? (
             <></>
            )
            :  <p>VIEW DETAILS </p>} */}
                </div>
            }
          </div>
        </div>
        <div>

          {paymentStatus == 'pending'
            ? <p className={classNames(css.statusPending, css.status)}>Pending</p>
            : null}

          {/* {paymentStatus == 'declined' && currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_DECLINE
            ? (
              <p className={classNames(css.statusDeclined, css.status)}>
                Declined
              </p>
            )
            : null} */}

        </div>
      </div>
    </div>
  );
};

ListingCardInboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardInboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  bookmarkedData: (id) => dispatch(updateProfile(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(ListingCardInboxComponent);
