import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './IconStar.module.css';

const IconStar = props => {
  const { className, rootClassName, isOpen } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0L9.8175 4.93691L15 5.73344L11.25 9.57413L12.135 15L7.5 12.4369L2.865 15L3.75 9.57413L0 5.73344L5.1825 4.93691L7.5 0Z"
        fill="#F887DC"
      />
    </svg>
  );
};

IconStar.defaultProps = {
  className: null,
  rootClassName: null,
  isOpen: true,
};

IconStar.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool,
};

export default IconStar;
