import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_BYBORROW_FEE,
  LINE_ITEM_BYBORROW_COMMISSION,
} from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0]
    ? lineItems[0].lineTotal.currency
    : config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_BYBORROW_COMMISSION ||
    lineItem.code === LINE_ITEM_BYBORROW_FEE
  );
};

/**
 * Returns non-commission, non-reversal line items
 */
const nonCommissionNonReversalLineItems = lineItems => {
  return lineItems.filter(item => !isCommission(item) && !item.reversal);
};

/**
 * Check if there is a commission line-item for the given user role.
 */
const hasCommission = (lineItems, userRole) => {
  let commissionLineItem = null;

  if (userRole === 'customer') {
    commissionLineItem = lineItems.find(
      item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
    );
  } else if (userRole === 'provider') {
    commissionLineItem = lineItems.find(
      item => item.code === LINE_ITEM_PROVIDER_COMMISSION
    );
  }
  return !!commissionLineItem;
};

const LineItemCustomTotal = props => {
  const { lineItems, unitType, userRole, intl } = props;

  const refund = lineItems.find(
    item => item.code === unitType && item.reversal
  );

  // Show unit purchase line total (unit price * quantity) as a subtotal.
  // PLEASE NOTE that this assumes that the transaction doesn't have other
  // line item types than the defined unit type (e.g. week, month, year).
  const showSubTotal = hasCommission(lineItems, userRole) || refund;

  // all non-reversal, non-commission line items
  const subTotalLineItems = nonCommissionNonReversalLineItems(lineItems);
  // line totals of those line items combined
  const subTotal = lineItemsTotal(subTotalLineItems);
  const formattedSubTotal =
    subTotalLineItems.length > 0 ? formatMoney(intl, subTotal) : null;

  const cartItems =
    lineItems && lineItems.filter(l => l?.code?.includes('line-item/units'));
  const unitsPrices = cartItems && cartItems.map(item => item.unitPrice);
  const sum = unitsPrices.reduce(function(acc, obj) {
    return acc + obj.amount;
  }, 0);
  const customTotal =
    sum && new Money(sum, 'USD') && formatMoney(intl, new Money(sum, 'USD'));

  return (
    <>
      {/* <hr className={css.totalDivider} /> */}
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.subTotal" />
        </span>
        <span className={css.itemValue}>{customTotal}</span>
      </div>
    </>
  );
};

LineItemCustomTotal.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  userRole: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomTotal;
