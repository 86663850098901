import React from 'react';
import loadable from '@loadable/component';

import config from '../config';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const AboutUsPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AboutUs/AboutUsPage'));
const BlogPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/BlogPage/BlogPage'));
const PricingGuidePage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/PricingGuidePage/PricingGuidePage'));
const BlogPostPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/BlogPostPage/BlogPostPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage'));
const CMSLandingPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSLandingPage/CMSLandingPage'));
// const DynamicContentPage = loadable(() => import(/* webpackChunkName: "DynamicContentPage" */ '../containers/DynamicContentPage/DynamicContentPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const FaqPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/Faq/FaqPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const CartPage = loadable(() => import(/* webpackChunkName: "CartPage" */ '../containers/CartPage/CartPage'));
const ListingPageHeroImage = loadable(() => import(/* webpackChunkName: "ListingPageHeroImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageHeroImage'));
const ListingPageFullImage = loadable(() => import(/* webpackChunkName: "ListingPageFullImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageFullImage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const DashboardPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/Dashboard/Dashboard'));
const CollectionProductPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/Collection/CollectionProduct'));
const CollectionPage =loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/Collection/CollectionPage'));
const BookingPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/BookingPage/BookingPage'));
const DetailsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/RentalViewDetails/DetailsPage'));

const NewsletterPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/NewsletterPage/NewsletterPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'));
const SearchPageWithList = loadable(() => import(/* webpackChunkName: "SearchPageWithList" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithList'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const WorkWithUsPage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/WorkWithUs/WorkWithUsPage'));
const HowItWorksPage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/HowItWorksPage/HowItWorksPage'));
const HowToRentPage =loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/HowToRentPage/HowToRentPage'));
const HowToLendPage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/HowToLend/HowToLendPage'));
const  PricingDeliveryTipsPage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/PricingDeliveryTips/PricingDeliveryTipsPage'));
const  ProfileTipsPage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/ProfileTips/ProfileTipsPage'));
const ContactUsPage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/ContactUsPage/ContactUsPage'));
const InvitePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/InvitePage/InvitePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const TransactionPageRent = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPageRent/TransactionPageRent'));
const TermOfServicePage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TermOfServicePage/TermOfServicePage'));
// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));


const SearchPage = config.searchPageVariant === 'map' ? SearchPageWithMap : SearchPageWithList;
const ListingPage = config.listingPageLayout === 'full-image' ? ListingPageFullImage : ListingPageHeroImage;

export const ACCOUNT_SETTINGS_PAGES = [
  'StripePayoutPage',
  'PaymentMethodsPage',
];
export const PROFILE_SETTINGS_PAGES = [
  'ProfileSettingsPage',
  'ContactDetailsPage',
  'PasswordChangePage',
];
// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    // {
    //   path: '/landing',
    //   // name: 'LandingPage',
    //   component: LandingPage,
    //   loadData: pageDataLoadingAPI.LandingPage.loadData,
    // },
    {
      path: '/',
      name: 'LandingPage',
      component: CMSLandingPage,
      loadData: pageDataLoadingAPI.CMSLandingPage.loadData,
    },

    {
      path: '/cart',
      name: 'CartPage',
      component: CartPage,
      loadData: pageDataLoadingAPI.CartPage.loadData,
    },
    {
      path: '/cart/login',
      name: 'CartPageLogin',
      component: CartPage,
      auth: true,
      loadData: pageDataLoadingAPI.CartPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
      // loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/Invite',
      name: 'InvitePage',
      component: InvitePage,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      // loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
      // loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/dashboard',
      name: 'DashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardPage,
       loadData: pageDataLoadingAPI.DashboardPage.loadData,
    },
    {
      path: '/collection',
      name: 'CollectionPage',
      // auth: true,
      // authPage: 'LoginPage',
      component: CollectionPage,
       loadData: pageDataLoadingAPI.CollectionPage.loadData,
    },
  
    {
      path: '/collection/:id',
      name: 'CollectionProductPage',
      auth: true,
      authPage: 'LoginPage',
      component: CollectionProductPage,
       loadData: pageDataLoadingAPI.CollectionProductPage.loadData,
    },
    {
      path: '/bookings',
      name: 'BookingBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="BookingPage" params={{ type: 'rent' }} />,
    },
    {
      path: '/bookings/:type',
      name: 'BookingPage',
      auth: true,
      authPage: 'LoginPage',
      component: BookingPage,
       loadData: pageDataLoadingAPI.BookingPage.loadData,
    },
    {
      path: '/rentail_details',
      name: 'DetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: DetailsPage,
      loadData: params =>
      pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
     setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,    },
    
    
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details/:type',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/rentorder/:id/details/:type',
      name: 'RentOrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPageRent,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPageRent.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPageRent.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details/:type',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/rent/:id/details/:type',
      name: 'RentDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPageRent,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPageRent.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
   
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="StripePayoutPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    // {
    //   path: '/terms-of-service',
    //   name: 'TermsOfServicePage',
    //   component: TermsOfServicePage,
    // },
    {
      path: '/contact_us',
      name: 'ContactUsPage',
      component: ContactUsPage,
    },
    // {
    //   path: '/k',
    //   name: 'PrivacyPolicyPage',
    //   component: PrivacyPolicyPage,
    // },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // {
    //   path: '/:tab',
    //   name: 'ContentPage',
    //   component: DynamicContentPage,
    //   loadData: pageDataLoadingAPI.DynamicContentPage.loadData,
    // },
    // {
    //   path: '/:tab/:id',
    //   name: 'ContentDetailsPage',
    //   component: DynamicContentPage,
    //   loadData: pageDataLoadingAPI.DynamicContentPage.loadData,
    // },

    {
      path: '/news-letter',
      name: 'NewsletterPage',
      component: NewsletterPage,
    },

    //CMS Pages

    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },

    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermOfServicePage',
      component: TermOfServicePage,
      loadData: pageDataLoadingAPI.TermOfServicePage.loadData,
    },
    {
      path: '/about-us',
      name: 'AboutUsPage',
      component: AboutUsPage,
      loadData: pageDataLoadingAPI.AboutUsPage.loadData,
    },
    {
      path: '/work-with-us',
      name: 'WorkWithUsPage',
      component: WorkWithUsPage,
      loadData: pageDataLoadingAPI.WorkWithUsPage.loadData,
    },
    {
      path: '/how-it-works',
      name: 'HowItWorksPage',
      component: HowItWorksPage,
      loadData: pageDataLoadingAPI.HowItWorksPage.loadData,
    },
    {
      path: '/how-to-rent',
      name: 'HowToRentPage',
      component: HowToRentPage,
      loadData: pageDataLoadingAPI.HowToRentPage.loadData,
    },
    {
      path: '/faq',
      name: 'FaqPage',
      component: FaqPage,
      loadData: pageDataLoadingAPI.FaqPage.loadData,
    },
    {
      path: '/pricing-guide',
      name: 'PricingGuidePage',
      component: PricingGuidePage,
      loadData: pageDataLoadingAPI.PricingGuidePage.loadData,
    },
    {
      path: '/blogs',
      name: 'BlogPage',
      component: BlogPage,
      loadData: pageDataLoadingAPI.BlogPage.loadData,
    },
    {
      path: '/how_to_lend',
      name: 'HowToLendPage',
      component: HowToLendPage,
      loadData: pageDataLoadingAPI.HowToLendPage.loadData,
    },
    {
      path: '/profile-tips',
      name: 'ProfileTipsPage',
      component: ProfileTipsPage,
      loadData: pageDataLoadingAPI.ProfileTipsPage.loadData,
    },
    {
      path: '/pricing-delivery-tips',
      name: 'PricingDeliveryTips',
      component: PricingDeliveryTipsPage,
      loadData: pageDataLoadingAPI.PricingDeliveryTipsPage.loadData,
    },
    {
      path: '/blogs/:slug',
      name: 'BlogPostPage',
      component: BlogPostPage,
      loadData: pageDataLoadingAPI.BlogPostPage.loadData,
    },
   
  ];
};

export default routeConfiguration;
