import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parseDateFromISO8601, getExclusiveEndDate } from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities, clearMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getConfigData } from '../../containers/EditListingPage/EditListingPage.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_CUSTOM_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_CUSTOM_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_FILTER_REQUEST = 'app/SearchPage/SEARCH_FILTER_REQUEST';
export const SEARCH_FILTER_SUCCESS = 'app/SearchPage/SEARCH_FILTER_SUCCESS';
export const SEARCH_FILTER_ERROR = 'app/SearchPage/SEARCH_FILTER_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  customPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  filterProducts: {},
  searchFilterInProgress: false,
  searchFilterError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_CUSTOM_LISTINGS_SUCCESS:
      return {
        ...state,
        customPageResultIds: resultIds(payload.data),
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_FILTER_REQUEST:
      return {
        ...state,
        searchFilterInProgress: true,
        searchMapListingIds: [],
        searchFilterError: null,
      };
    case SEARCH_FILTER_SUCCESS:
      return {
        ...state,
        filterProducts: payload.data,
        searchFilterInProgress: false,
      };
    case SEARCH_FILTER_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchFilterInProgress: false, searchFilterError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchCustomListingsSuccess = response => ({
  type: SEARCH_CUSTOM_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchFilterRequest = filterProducts => ({
  type: SEARCH_FILTER_REQUEST,
  payload: { filterProducts },
});

export const searchFilterSuccess = response => ({
  type: SEARCH_FILTER_SUCCESS,
  payload: { data: response },
});

export const searchFilterError = e => ({
  type: SEARCH_FILTER_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.lineItemUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking
        ? values[1]
        : hasValues
          ? getExclusiveEndDate(values[1], 'Etc/UTC')
          : null;

    return hasValues
      ? {
        start: parseDateFromISO8601(startDate, 'Etc/UTC'),
        end: parseDateFromISO8601(endDate, 'Etc/UTC'),
        // Availability can be full or partial. Default value is full.
        availability: 'full',
      }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    per_page: perPage,
  };

  dispatch(clearMarketplaceEntities());
  return sdk.listings
    .query(params)
    .then(async response => {
      dispatch(addMarketplaceEntities(response));
      const res = await sdk.listings.query({});
      dispatch(searchCustomListingsSuccess(res));
      dispatch(searchListingsSuccess(response));
      // dispatch(getSearchFilters({
      //   brand: [],
      //   size: [],
      //   colour: [],
      //   category: [],
      //   subCategory:[]
      // }));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

// export const getSearchFilters = filterProducts => (dispatch, getState, sdk) => {
//   dispatch(searchFilterRequest(filterProducts));
//   const results = getState().marketplaceData.entities.listing;
//   const stateValues = getState().EditListingPage && getState().EditListingPage.filterConfigValues;

//   if (results && Object.keys(results).length) {
//     Object.keys(filterProducts).map(key => {
//       let data = [];
//       const cacheKey = (key) => {
//         let KeyMatch = ' ';
//         switch (key) {
//           case 'brand':
//             KeyMatch = 'brands';
//             break;
//           case 'colour':
//             KeyMatch = 'colors';
//             break;
//           case 'size':
//             KeyMatch = 'sizes';
//             break;
//           case 'category':
//             KeyMatch = 'categories';
//             break;
//             case 'subCategory':
//             KeyMatch = 'subProductTypes';
//             break;
//           default:
//             break;
//         }
//         return KeyMatch;
//       }
//       const filterValues = stateValues && Object.entries(stateValues).filter(ent => ent[0] == cacheKey(key) && ent[0])[0][0, 0];
//       console.log(stateValues, '&&&  &&& => stateValues');
      
//       Object.values(results).map(r => {
//         if (r.attributes.publicData[key] && Array.isArray(r.attributes.publicData[key])) {
//           data = [...data, ...r.attributes.publicData[key]];
//         } else if (r.attributes.publicData[key] && Object.keys(r.attributes.publicData[key]).length && typeof r.attributes.publicData[key] == "object") {
//           data = [...data, ...[r.attributes.publicData[key]]];
//         } else if (filterValues && filterValues.length) {
//           data = [...data, ...filterValues.filter(o => (o.key == r.attributes.publicData[key] || o.label == r.attributes.publicData[key]))];
//         }
//       });

//       filterProducts[key] = [...filterProducts[key], ...data];
//       filterProducts[key] = filterProducts[key].filter((value, index, self) => index == self.findIndex((t) => (t.key == value.key)));
//     });

//     const prices = Object.values(results).map(st => st.attributes?.price?.amount);
//     const minPrice = parseInt(Math.min(...prices) / 100);
//     const maxPrice = parseInt(Math.max(...prices) / 100);
//     filterProducts['price'] = [minPrice, maxPrice];
//   }
//   dispatch(searchFilterSuccess(filterProducts));
//   return results;
// };

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => (dispatch) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return Promise.all([dispatch(searchListings({
    ...minStockMaybe,
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images', 'author.profileImage'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', `variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 4,
  })), dispatch(getConfigData())
  ]);
};
