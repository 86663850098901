import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CLEANING_FEE, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemCleaningFeeMaybe = props => {
  const { lineItems, intl } = props;

  const cleaningFeeLineItem = lineItems.filter(
    item => item.code === LINE_ITEM_CLEANING_FEE 
  );

  const cleaningPrices = cleaningFeeLineItem && cleaningFeeLineItem.map(item => item.lineTotal);
  const sum = cleaningPrices.reduce(function (acc, obj) { return acc + obj.amount; }, 0);
  const totalCleaning = sum && new Money(sum,config.currency) && formatMoney(intl, new Money(sum, config.currency));

  return cleaningFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.cleaningFee" />
      </span>
      <span className={css.itemValue}>{totalCleaning}</span>
    </div>
  ) : null;
};

LineItemCleaningFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCleaningFeeMaybe;
