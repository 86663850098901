/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as DynamicContentPageLoader } from './DynamicContentPage/DynamicContentPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as BookingPageLoader } from './BookingPage/BookingPage.duck';
import {loadData as PurchasePageLoader}from './RentalViewDetails/DetailsPage.duck'
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
// import { loadData as AuthenticationPageLoader } from '../ducks/Auth.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as CartPageLoader } from './CartPage/CartPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as AboutUsPageLoader } from './AboutUs/AboutUsPage.duck';
import { loadData as WorkWithUsPageLoader } from './WorkWithUs/WorkWithUsPage.duck';
import { loadData as HowItWorksPageLoader } from './HowItWorksPage/HowItWorksPage.duck';
import {loadData as HowToRentPageLoader} from './HowToRentPage/HowToRentPage.duck';
import { loadData as PricingDeliveryTipsPageLoader } from './PricingDeliveryTips/PricingDeliveryTipsPage.duck';
import { loadData as BlogPageLoader } from './BlogPage/BlogPage.duck';
import { loadData as ProfileTipsPageLoader } from './ProfileTips/ProfileTipsPage.duck';
import {loadData as HowToLendPageLoader} from './HowToLend/HowToLendPage.duck'
import { loadData as BlogPostPageLoader } from './BlogPostPage/BlogPostPage.duck';
import { loadData as TermOfServicePageLoader } from './TermOfServicePage/TermOfServicePage.duck';
import { loadData as FaqPageLoader } from './Faq/FaqPage.duck';
import {loadData as DashboardPageLoader} from './Dashboard/Dashboard.duck'
import {loadData as CollectionPageLoader} from './Collection/CollectionPage.duck'
import {loadData as CollectionProductPageLoader} from './Collection/CollectionPage.duck'

import { loadData as CMSLandingPageLoader } from './CMSLandingPage/CMSLandingPage.duck';
import { loadData as PricingGuidePageLoader } from './PricingGuidePage/PricingGuidePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import {
  loadData as TransactionRentPageLoader,
  setInitialValues as TransactionRentPageInitialValues,
} from './TransactionPageRent/TransactionPageRent.duck';

const getPageDataLoadingAPI = () => {
  return {
    CartPage: {
      loadData: CartPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    CMSLandingPage: {
      loadData: CMSLandingPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    DynamicContentPage: {
      loadData: DynamicContentPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    BookingPage:{
      loadData : BookingPageLoader,
    },
    DashboardPage:{
      loadData :DashboardPageLoader
    },
    CollectionPage:{
      loadData:CollectionPageLoader
    },
    CollectionProductPage:{
      loadData:CollectionProductPageLoader
    },
    PurchasePage:{
      loadData : PurchasePageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    // AuthenticationPage: {
    //   loadData: AuthenticationPageLoader,
    // },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    TransactionPageRent: {
      loadData: TransactionRentPageLoader,
      setInitialValues: TransactionRentPageInitialValues,
    },

    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    TermOfServicePage: {
      loadData: TermOfServicePageLoader,
    },
    AboutUsPage: {
      loadData: AboutUsPageLoader,
    },
    WorkWithUsPage: {
      loadData: WorkWithUsPageLoader,
    },
    HowItWorksPage: {
      loadData: HowItWorksPageLoader,
    },
    HowToRentPage:{
      loadData:HowToRentPageLoader,
    },
    
    BlogPage: {
      loadData: BlogPageLoader,
    },
    HowToLendPage:{
      loadData: HowToLendPageLoader,
    },
    ProfileTipsPage:{
      loadData:ProfileTipsPageLoader,
    },
    PricingDeliveryTipsPage:{
      loadData:PricingDeliveryTipsPageLoader,
    },
    BlogPostPage: {
      loadData: BlogPostPageLoader,
    },
    FaqPage: {
      loadData: FaqPageLoader,
    },
    PricingGuidePage: {
      loadData: PricingGuidePageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
